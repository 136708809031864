import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SecurityService } from '../../security/security.service';
import { AppUserAuth } from '../../security/app-user-auth';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { NewOrderGroup } from '../../_interfaces/NewOrderGroup';
import { AccountingService } from '../../Services/AccountingService';
import { DashboardService } from '../../Services/DashboardService';

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.css']
})
export class NewOrderComponent implements OnInit {
  securityObject: AppUserAuth;
  data;
  rowGroupMetadata: any;
  filteredValues;
  colTotal;
  summaryTotal;
  loading: boolean = false;
  sYear: number = new Date().getFullYear();
  sMonth: number = new Date().getMonth() + 1;
  eYear: number = new Date().getFullYear();
  eMonth: number = new Date().getMonth() + 1;
  Years: number[] = [this.sYear];
  totalRevenue: number = 0;
  totalQuantity: number = 0;
  Months = [
    {
      "key": 1,
      "value": "January"
    },
    {
      "key": 2,
      "value": "February"
    },
    {
      "key": 3,
      "value": "March"
    },
    {
      "key": 4,
      "value": "April"
    },
    {
      "key": 5,
      "value": "May"
    },
    {
      "key": 6,
      "value": "June"
    },
    {
      "key": 7,
      "value": "July"
    },
    {
      "key": 8,
      "value": "August"
    },
    {
      "key": 9,
      "value": "September"
    },
    {
      "key": 10,
      "value": "October"
    },
    {
      "key": 11,
      "value": "November"
    },
    {
      "key": 12,
      "value": "December"
    }
  ];
  cols;
  newOrderColumns;
  _selectedColumns: any[];
  //public temDataLength: number = 0;
  //public isExpanded: boolean = false;
  //public expandedRows = {};
  groupTotals: NewOrderGroup[] = [];

  constructor(private router: Router, private securityService: SecurityService,
    public dashboardService: DashboardService, private accountingService: AccountingService) 
   { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    if (!this.securityObject.IsAuthenticated) { this.router.navigate(['../../']) };
    this.dashboardService.getSalesPersonFilter();
    this.Years[0] = this.sYear;
    for (let i = this.sYear - 1; i > 2012; i--) { this.Years.push(i); }
    this.getData(this.sMonth, this.sYear, this.eMonth, this.eYear);
    this.cols = [
      { field: 'OrderDate', header: 'Order Date' },
      { field: 'OrderID', header: 'Order #' },
      { field: 'InHomeDate', header: 'In Home Date' },
      { field: 'Customer', header: 'Customer' },
      { field: 'CityState', header: 'City State' },
      { field: 'SalesPerson', header: 'Salesperson' },
      { field: 'Template', header: 'Template' },
      { field: 'Quantity', header: 'Quantity' },
      { field: 'Revenue', header: 'Revenue' },
      { field: 'NoOfCalls', header: '# of Calls' }
    ];
    this.colTotal = [
      { field: 'OrderDate', Total: '' },
      { field: 'OrderID', Total: '' },
      { field: 'InHomeDate', Total: '' },
      { field: 'Customer', Total: ''},
      { field: 'CityState', Total: '' },
      { field: 'SalesPerson', Total: '' },
      { field: 'Template', Total: '' },
      { field: 'Quantity', Total: '0'},
      { field: 'Revenue', Total: '0' },
      { field: 'NoOfCalls', Total: '0' }
    ];
   
    this._selectedColumns = this.cols;
    this.newOrderColumns = JSON.parse(window.localStorage.getItem('newOrderColumns'));
    if (this.newOrderColumns === null) { window.localStorage.setItem('newOrderColumns', JSON.stringify(this._selectedColumns)); }
    else { this._selectedColumns = this.newOrderColumns }
  }

  @Input() get selectedColumns(): any[] {
    var x = this._selectedColumns;
    window.localStorage.setItem('newOrderColumns', JSON.stringify(x));
    return x;
  }

  set selectedColumns(val: any[]) {  //restore original order
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    window.localStorage.setItem('newOrderColumns', JSON.stringify(this.selectedColumns));
  }

  saveReorderedColumns(event: any) { window.localStorage.setItem('newOrderColumns', JSON.stringify(this.selectedColumns)); }


  getData(sm, sy, em, ey) {
    this.loading = true;
    var s;
    var e;
    if (sm.length < 2) sm = '0' + sm;
    s = [sy, sm, '01'].join('-');
    if (em.length < 2) em = '0' + em;
    e = [ey, em, '01'].join('-');

    this.accountingService.getNewOrders(s, e).subscribe(data => {
      this.data = data;
      this.summaryTotal = this.data.length;
      this.getSum()
      this.loading = false;
    });
  }

  getSum() {
    for (let i = 0; i < this.colTotal.length; i++) {
      var fieldSum = 0;
      if (this.colTotal[i].Total !== '') {
        for (let x = 0; x < this.data.length; x++) {
          fieldSum += this.data[x][this.colTotal[i]['field']];
        }
        this.colTotal[i]['Total'] = fieldSum;

      }
    }
  }

  onFilter(event, dt) {
    this.filteredValues = event.filteredValue;
    for (let i = 0; i < this.colTotal.length; i++) {
      var fieldSum = 0;
      if (this.colTotal[i].Total !== '') {
        for (let x = 0; x < this.filteredValues.length; x++) {
          fieldSum += this.filteredValues[x][this.colTotal[i]['field']];
        }
        this.colTotal[i]['Total'] = fieldSum;
        this.summaryTotal = this.filteredValues.length;
      }
    }
  }

  
}


