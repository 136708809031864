import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppUser } from '../security/app-user';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable({ providedIn: 'root' })
export class OrderService {

  constructor(private http: HttpClient) { }

  private headers: HttpHeaders;

  getOrders(a) { return this.http.get('/Query/GetOrders', { params: new HttpParams().set("a", a) }); }

  getOrdersSales(a, u) { return this.http.get('/Query/GetOrdersSales', { params: new HttpParams().set("a", a).set("u", u) }); }

  getOrder(id) { return this.http.get('api/Orders/' + id); }

  addOrder(o) { return this.http.post('api/Orders/', o); }

  cloneOrder(id) {
    return this.http.get('api/Orders/' + id + "/clone");
  }

  deleteOrder(id) { return this.http.delete('api/Orders/' + id); }

  updateOrder(c) { return this.http.put('api/Orders/' + c.ID, c, { headers: this.headers }); }

  logOrder(oldID, user, newID, chgType) { return this.http.get('Query/LogOrder', { params: new HttpParams().set("oldID", oldID).set("user", user).set("newID", newID).set("chgType", chgType) }); }

  GetOrderCustDisplayItems(id) { return this.http.get('api/Customers/' + id + '/order') }

  GetServices(id) { return this.http.get('api/ServiceMappings/' + id + '/order') }

  GetServicesDropdown() { return this.http.get('Query/GetServices/') }

  addService(c) { return this.http.post('api/ServiceMappings/', c); }

  updateService(c) { return this.http.put('api/ServiceMappings/' + c.ID, c, { headers: this.headers }); }

  deleteService(id) { return this.http.delete('api/ServiceMappings/' + id); }

  GetOrderPayments(id) { return this.http.get('Query/GetOrderPayments/' + id) }

  GetOrderCosts(id) { return this.http.get('Query/GetOrderCosts/' + id) }

  GetOrderItems(id) { return this.http.get('Query/GetOrderItems/' + id) }

  GetTemplateImage(id) { return this.http.get('api/TemplateImages/' + id, { responseType: 'blob' }) }

  getItem(id) { return this.http.get('api/Items/' + id); }

  addItem(i) { return this.http.post('api/Items/', i); }

  updateItem(i) { return this.http.put('api/Items/' + i.ID, i, { headers: this.headers }); }

  deleteItem(id) { return this.http.delete('api/Items/' + id); }

  getItemPayments(id) { return this.http.get('api/Payments/' + id + '/item') }

  updatePayment(p) { return this.http.put('api/Payments/' + p.ID, p, { headers: this.headers }); }

  addPayment(p) { return this.http.post('api/Payments/', p); }

  deletePayment(id) { return this.http.delete('api/Payments/' + id); }

  GetNextOrderID() { return this.http.get('Query/GetNextOrderID') }

  getBlackOutDates() { return this.http.get('Maintenance/getBlackOutDates/') }

  getListCosts() { return this.http.get('Query/getListCosts/') }

  getPrinterNames() { return this.http.get('Query/GetPrinterNamesDropdown/') }

  addFileUpload(m) { return this.http.post('/api/File/', m ) }
  addQCFileUpload(m) { return this.http.post('/api/File/PostQC', m ) }



  // getExternalDSOInfo(a, b) { return this.http.get('/api/graphQL', { params: new HttpParams().set("a", a).set("b", b) }); }
}
