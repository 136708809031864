import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from '../../Services/DashboardService';
import { AppUserAuth } from '../../security/app-user-auth';
import { Router } from '@angular/router';
import { SecurityService } from '../../security/security.service';
import * as alertify from 'alertifyjs';

@Component({
  selector: 'app-pending',
  templateUrl: './pending.component.html',
  styleUrls: ['../dashboard.component.css']
})
export class PendingComponent implements OnInit {
  securityObject: AppUserAuth;
  data;
  cols: any[];
  _selectedColumns: any[];
  pendingCompletionColumns;

  constructor(private router: Router, private securityService: SecurityService, public dashBoard: DashboardService) { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    if (!this.securityObject.IsAuthenticated || (this.securityObject.Role === "Design" || this.securityObject.Role === "Printer" || this.securityObject.Role === "Mailer" || this.securityObject.Role === "Shipper")) { this.router.navigate(['../../']) };
    if (this.securityObject.Role === 'Sales') { this.dashBoard.getDashboardPendingCompletionSales(this.securityObject.UserName).subscribe(data => { this.data = data; }, error => { alertify.error(error.message) }); }
    else { this.dashBoard.getDashboardPendingCompletion().subscribe(data => { this.data = data; }, error => { alertify.error(error.message) }); }
    this.cols = [
      { field: 'Status', header: 'Status' },
      { field: 'OrderID', header: 'Order #' },
      { field: 'PieceType', header: 'Item' },
      { field: 'ItemNumber', header: 'Item #' },
      { field: 'Company', header: 'Company' },
      { field: 'InHomeDate', header: 'In Home Date' },
      { field: 'BColor', header: '' },
      { field: 'SID', header: '' },
    ];
    this.cols = this.cols.filter(col => col.header !== '')
    this._selectedColumns = this.cols;
    this.pendingCompletionColumns = JSON.parse(window.localStorage.getItem('pendingCompletionColumns'));
    if (this.pendingCompletionColumns === null) { window.localStorage.setItem('pendingCompletionColumns', JSON.stringify(this._selectedColumns)); }
    else { this._selectedColumns = this.pendingCompletionColumns }
  }

  @Input() get selectedColumns(): any[] {
    var x = this._selectedColumns;
    window.localStorage.setItem('pendingCompletionColumns', JSON.stringify(x));
    return x;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    window.localStorage.setItem('pendingCompletionColumns', JSON.stringify(this.selectedColumns));
  }

  saveReorderedColumns(event: any) {
    window.localStorage.setItem('pendingCompletionColumns', JSON.stringify(this.selectedColumns));
  }
}

