import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SecurityService } from '../../security/security.service';
import { AppUserAuth } from '../../security/app-user-auth';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { NewSalesGroup } from '../../_interfaces/NewOrderGroup';
import { AccountingService } from '../../Services/AccountingService';
import { MaintenanceService } from '../../Services/MaintenanceService';

@Component({
  selector: 'app-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.css']
})
export class SalesReportComponent implements OnInit {
  securityObject: AppUserAuth;
  data;
  rowGroupMetadata: any;
  loading: boolean = false;
  year: number = new Date().getFullYear();
  Years: number[] = [this.year];
  totalReceived: number = 0;
  totalQuantity: number = 0;
  totalSales: number = 0;
  AvgPieces: number = 0;
  cols;
  groupTotals: NewSalesGroup[] = [];
  subTitle = "In Home Date";

  constructor(private router: Router, private securityService: SecurityService,
    private accountingService: AccountingService, private maintenanceService: MaintenanceService) { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    if (!this.securityObject.IsAuthenticated) { this.router.navigate(['../../']) };
    this.Years[0] = this.year;
    for (let i = this.year - 1; i > 2012; i--) { this.Years.push(i); }
    this.cols = [
      { field: 'M', header: 'Month' },
      { field: 'Sales', header: 'Sales' },
      { field: 'Received', header: 'Received' },
      { field: 'Quantity', header: 'Quantity' },
      { field: 'AvgPieces', header: '$ Per Piece' }
    ];
    this.getData(this.year);
  }

  getData(y) {
    this.loading = true;
    if (this.subTitle !== "Received Date") {
      this.accountingService.getSalesRptInHome(y).subscribe(data => {
        this.data = data;
        var distinct = this.data.map(item => item.M).filter((value, index, self) => self.indexOf(value) === index)
        this.groupTotals = [];
        for (let i = 0; i < distinct.length; i++) {
          let g = { M: distinct[i], Q: 0, R: 0, S: 0, A: 0 };
          var a = this.data.filter(d => d.M === distinct[i]);
          for (let j = 0; j < a.length; j++) {

            g.Q += a[j].Quantity;
            g.R += a[j].Received;
            g.S += a[j].Sales;
            g.A = a[j].AvgPieces;
          }
          this.groupTotals.push(g);
        }
        this.getSum(data);
        this.loading = false;
      });
    }
    else {
      this.accountingService.getSalesRptRec(y).subscribe(data => {
        this.data = data;
        var distinct = this.data.map(item => item.M).filter((value, index, self) => self.indexOf(value) === index)
        this.groupTotals = [];
        for (let i = 0; i < distinct.length; i++) {
          let g = { M: distinct[i], Q: 0, R: 0, S: 0, A: 0 };
          var a = this.data.filter(d => d.M === distinct[i]);
          for (let j = 0; j < a.length; j++) {

            g.Q += a[j].Quantity;
            g.R += a[j].Received;
            g.S += a[j].Sales;
            g.A = a[j].AvgPieces;
          }
          this.groupTotals.push(g);
        }
        this.getSum(data);
        this.loading = false;
      });
    }
  }

  onSort() { this.updateRowGroupMetaData(); }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.data) {
      for (let i = 0; i < this.data.length; i++) {
        let rowData = this.data[i];
        let M = rowData.M;
        if (i == 0) { this.rowGroupMetadata[M] = { index: 0, size: 1 }; }
        else {
          let previousRowData = this.data[i - 1];
          let previousRowGroup = previousRowData.M;
          if (M === previousRowGroup) this.rowGroupMetadata[M].size++;
          else this.rowGroupMetadata[M] = { index: i, size: 1 };
        }
      }
    }
  }

  getGroupTotals(d, x) {
    var a = this.groupTotals.filter(i => i.M === d);
    if (x === 'Q') return a[0].Q;
    if (x === 'S') return a[0].S;
    if (x === 'R') return a[0].R;
    if (x === 'A') return a[0].A;
  }

  getSum(data) {
    this.totalReceived = 0;
    this.totalQuantity = 0;
    this.totalSales = 0;
    for (let x = 0; x < data.length; x++) {
      this.totalQuantity += data[x]['Quantity'];
      this.totalReceived += data[x]['Received'];
      this.totalSales += data[x]['Sales'];
    }
    this.AvgPieces = this.totalSales / this.totalQuantity;
  }

  onFilter(event, dt) {
    if (dt.filteredValue !== null) { this.getSum(dt.filteredValue); }
    else { this.getSum(this.data) }
  }

  getFilterChange() { this.getData(this.year); }

  IHDate() {
    //this.cols[1].header = "In Home Date";
    this.subTitle = "In Home Date";
    this.getData(this.year);
  }

  RecDate() {
    //this.cols[1].header = "Received Date";
    this.subTitle = "Received Date";
    this.getData(this.year);
  }
}


