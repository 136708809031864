import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SecurityService } from '../../security/security.service';
import { AppUserAuth } from '../../security/app-user-auth';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { DashboardService } from '../../Services/DashboardService';
import { AccountingService } from '../../Services/AccountingService';
import { MaintenanceService } from '../../Services/MaintenanceService';

@Component({
  selector: 'app-organization-report',
  templateUrl: './organization-report.component.html',
  styleUrls: ['./organization-report.component.css']
})
export class OrganizationReportComponent implements OnInit {
  securityObject: AppUserAuth;
  data;
  rowGroupMetadata: any;
  loading: boolean = false;
  sYear: number = new Date().getFullYear();
  sMonth: number = new Date().getMonth() + 1;
  eYear: number = new Date().getFullYear();
  eMonth: number = new Date().getMonth() + 1;
  Years: number[] = [this.sYear];
  totalRevenue: number = 0;
  totalQuantity: number = 0;
  totalRebate: number = 0;
  Months = [
    {
      "key": 1,
      "value": "January"
    },
    {
      "key": 2,
      "value": "February"
    },
    {
      "key": 3,
      "value": "March"
    },
    {
      "key": 4,
      "value": "April"
    },
    {
      "key": 5,
      "value": "May"
    },
    {
      "key": 6,
      "value": "June"
    },
    {
      "key": 7,
      "value": "July"
    },
    {
      "key": 8,
      "value": "August"
    },
    {
      "key": 9,
      "value": "September"
    },
    {
      "key": 10,
      "value": "October"
    },
    {
      "key": 11,
      "value": "November"
    },
    {
      "key": 12,
      "value": "December"
    }
  ];
  cols;
  orgs;
  selectedOrg;
  searchType: string = "InHome";

  constructor(private router: Router, private securityService: SecurityService, public dashboardService: DashboardService,
    private accountingService: AccountingService, private maintenanceService: MaintenanceService) { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    if (!this.securityObject.IsAuthenticated) { this.router.navigate(['../../']) };
    this.maintenanceService.getOrganizations().subscribe(data => { this.orgs = data })
    this.dashboardService.getSalesPersonFilter();
    this.Years[0] = this.sYear;
    for (let i = this.sYear - 1; i > 2012; i--) { this.Years.push(i); }
    var d = new Date().getMonth()
    if (d >= 11) { this.Years.unshift(this.Years[0] + 1); }
    this.cols = [
      { field: 'OrderID', header: 'Item #' },
      { field: 'RecDate', header: 'Received Date' },
      { field: 'InHomeDate', header: 'In Home Date' },
      { field: 'Customer', header: 'Customer' },
      { field: 'CityState', header: 'City State' },
      { field: 'SalesPerson', header: 'Salesperson' },
      { field: 'Template', header: 'Template' },
      { field: 'Quantity', header: 'Quantity' },
      { field: 'Revenue', header: 'Revenue' },
      { field: 'Rebate', header: 'Rebate' },
      { field: 'NumberOfCalls', header: '# Calls' }
    ];
  }

  getData(sm, sy, em, ey, o) {
    this.loading = true;
    var xsm: number = +sm;
    var xem: number = +em;
    var xsy: number = +sy;
    var xey: number = +ey;
    var s;
    var e;
    var d = new Date(),
      month = '' + (xsm),
      day = '' + 1,
      year = sy;
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    s = [year, month, day].join('-');

    d = new Date(xey, xem + 1, 1);
    d.setDate(d.getDate() - 1);
    day = "1";
    month = d.getMonth().toString();
    year = d.getFullYear().toString();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    e = [year, month, day].join('-');

    if (this.searchType === 'InHome') {
      this.accountingService.getOrgRpt(s, e, o).subscribe(data => {
        this.data = data;
        this.getSum(data);
        this.loading = false;
      });
    }
    else {
      this.accountingService.getOrgRptRec(s, e, o).subscribe(data => {
        this.data = data;
        this.getSum(data);
        this.loading = false;
      });
    }
  }

  getSum(data) {
    this.totalQuantity = 0;
    this.totalRevenue = 0;
    this.totalRebate = 0;
    for (let x = 0; x < data.length; x++) {
      this.totalQuantity += data[x]['Quantity'];
      this.totalRevenue += data[x]['Revenue'];
      this.totalRebate += data[x]['Rebate'];
    }
  }

  onFilter(event, dt) {
    if (dt.filteredValue !== null) { this.getSum(dt.filteredValue); }
    else { this.getSum(this.data) }
  }

  getSelectedOrg(o) {
    this.selectedOrg = o;
    var oi = o.OrgCode + '#&';
    this.getData(this.sMonth, this.sYear, this.eMonth, this.eYear, oi);
  }
  getFilterChange() {
    var oi = this.selectedOrg.OrgCode + '#&';
    this.getData(this.sMonth, this.sYear, this.eMonth, this.eYear, oi);
  }

  Received() {
    this.searchType = 'Received';
    this.getFilterChange();
  }

  InHome() {
    this.searchType = 'InHome';
    this.getFilterChange();
  }

}


