import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable({ providedIn: 'root' })
export class MaintenanceService {

  constructor(private http: HttpClient) { }

  private headers: HttpHeaders;

  getBlackOutDates() { return this.http.get('/Maintenance/getBlackOutDates'); }

  saveBlackOutDate(m) { return this.http.put('/Maintenance/saveBlackOutDate/' + m.Id, m, { headers: this.headers }) }

  addBlackOutDate(m) { return this.http.get('/Maintenance/addBlackOutDate', { params: new HttpParams().set("d", m) }); }

  deleteBlackOutDate(m) { return this.http.delete('/Maintenance/deleteBlackOutDate/' + m) }

  getPrinters() { return this.http.get('api/Printers'); }

  savePrinter(m) { return this.http.put('api/Printers/' + m.ID, m, { headers: this.headers }) }

  addPrinter(m) { return this.http.post('api/Printers/', m); }

  deletePrinter(m) { return this.http.delete('api/Printers/' + m) }


  getIndustries() { return this.http.get('/Maintenance/getIndustries'); }

  saveIndustry(m) { return this.http.put('/Maintenance/saveIndustry/' + m.ID, m, { headers: this.headers }) }

  addIndustry(m) { return this.http.get('/Maintenance/addIndustry', { params: new HttpParams().set("d", m) }); }

  deleteIndustry(m) { return this.http.delete('/Maintenance/deleteIndustry/' + m) }


  getLeadSources() { return this.http.get('/Maintenance/getLeadSources'); }

  saveLeadSource(m) { return this.http.put('/Maintenance/saveLeadSource/' + m.ID, m, { headers: this.headers }) }

  addLeadSource(m) { return this.http.get('/Maintenance/addLeadSource', { params: new HttpParams().set("d", m) }); }

  deleteLeadSource(m) { return this.http.delete('/Maintenance/deleteLeadSource/' + m) }


  getGroupings() { return this.http.get('/Maintenance/getGroupings'); }

  saveGrouping(m) { return this.http.put('/Maintenance/saveGrouping/' + m.ID, m, { headers: this.headers }) }

  addGrouping(m) { return this.http.get('/Maintenance/addGrouping', { params: new HttpParams().set("d", m) }); }

  deleteGrouping(m) { return this.http.delete('/Maintenance/deleteGrouping/' + m) }

  getCustomers(n) { return this.http.get('/Maintenance/getCusts/', { params: new HttpParams().set("c", n) }); }


  getServiceCenters() { return this.http.get('/Maintenance/getServiceCenters'); }

  saveServiceCenter(m) { return this.http.put('/Maintenance/saveServiceCenter/' + m.ID, m, { headers: this.headers }) }

  addServiceCenter(m) { return this.http.get('/Maintenance/addServiceCenter', { params: new HttpParams().set("d", m) }); }

  deleteServiceCenter(m) { return this.http.delete('/Maintenance/deleteServiceCenter/' + m) }


  getServices() { return this.http.get('/Maintenance/getServices'); }

  saveService(m) { return this.http.put('/Maintenance/saveService/' + m.ID, m, { headers: this.headers }) }

  addService(m) { return this.http.get('/Maintenance/addService', { params: new HttpParams().set("sn", m.ServiceName).set("sf", m.ServiceFee) }); }

  deleteService(m) { return this.http.delete('/Maintenance/deleteService/' + m) }


  getPostcardSizes() { return this.http.get('/Maintenance/getPostcardSizes'); }

  savePostcardSize(m) { return this.http.put('/Maintenance/savePostcardSize/' + m.ID, m, { headers: this.headers }) }

  addPostcardSize(m) { return this.http.get('/Maintenance/addPostcardSize', { params: new HttpParams().set("sn", m.Size).set("sf", m.Price) }); }

  deletePostcardSize(m) { return this.http.delete('/Maintenance/deletePostcardSize/' + m) }


  getTemplateCatagories() { return this.http.get('/Maintenance/getTemplateCatagories'); }

  saveTemplateCatagory(m) { return this.http.put('/Maintenance/saveTemplateCatagory/' + m.ID, m, { headers: this.headers }) }

  addTemplateCatagory(m) { return this.http.get('/Maintenance/addTemplateCatagory', { params: new HttpParams().set("d", m) }); }

  deleteTemplateCatagory(m) { return this.http.delete('/Maintenance/deleteTemplateCatagory/' + m) }


  getOrganizations() { return this.http.get('/Maintenance/getOrganizations'); }

  saveOrganization(m) { return this.http.put('/Maintenance/saveOrganization/' + m.ID, m, { headers: this.headers }) }

  addOrganization(m) { return this.http.get('/Maintenance/addOrganization', { params: new HttpParams().set("sn", m.OrgName).set("sf", m.Rebate) }); }

  deleteOrganization(m) { return this.http.delete('/Maintenance/deleteOrganization/' + m) }


  getSalesPeople() { return this.http.get('/Maintenance/getSalesPeople'); }

  saveSalesPerson(m) { return this.http.put('/Maintenance/saveSalesPerson/' + m.ID, m, { headers: this.headers }) }

  addSalesPerson(m) { return this.http.get('/Maintenance/addSalesPerson', { params: new HttpParams().set("sn", m.Name).set("sf", m.Commission).set("un", m.UserID).set("cp", m.CellPhone) }); }

  deleteSalesPerson(m) { return this.http.delete('/Maintenance/deleteSalesPerson/' + m) }


  getStatus() { return this.http.get('/Maintenance/getStatus'); }

  saveStatus(m) { return this.http.put('/Maintenance/saveStatus/' + m.ID, m, { headers: this.headers }) }

  addStatus(m) { return this.http.get('/Maintenance/addStatus', { params: new HttpParams().set("sn", m.Name).set("pos", m.StatusPos).set("col", m.Color) }); }

  deleteStatus(m) { return this.http.delete('/Maintenance/deleteStatus/' + m) }


  getCompany(c) { return this.http.get('/Maintenance/getCompany', { params: new HttpParams().set("c", c) }); }

  saveCompany(m) {
    return this.http.put('/Maintenance/saveCompany/' + m.TID, m, { headers: this.headers }
    )
  }


}
