import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountingService } from '../../Services/AccountingService';
import { MaintenanceService } from '../../Services/MaintenanceService';
import { SecurityService } from '../../security/security.service';
import { AppUserAuth } from '../../security/app-user-auth';

@Component({
  selector: 'app-profit-loss',
  templateUrl: './profit-loss.component.html',
  styleUrls: ['./profit-loss.component.css']
})
export class ProfitLossComponent implements OnInit {
  securityObject: AppUserAuth;
  profitModel;
  y: number;
  m;
  Years: number[] = [];
  fromMonth = undefined;
  fromYear;
  PrinterDiscount1 = 0;
  PrinterDiscount2 = 0;
  PrinterDiscount3 = 0;
  MD1;
  MD2;
  MD3;
  subTitle = "In Home Date";

  constructor(private router: Router, private securityService: SecurityService,
    private accountingService: AccountingService, private maintenanceService: MaintenanceService) { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    if (!this.securityObject.IsAuthenticated) { this.router.navigate(['../../']) };
    this.y = new Date().getFullYear();
    this.m = new Date().getMonth();
    this.Years[0] = this.y;
    for (let i = this.y - 1; i > 2012; i--) { this.Years.push(i); }
    this.fromYear = this.y;
  }

  GetData() {
    var x = parseInt(this.fromMonth);
    this.MD1 = x + ' - ' + this.fromYear;
    this.MD2 = x + 1 + ' - ' + this.fromYear;
    this.MD3 = x + 2 + ' - ' + this.fromYear;
    if (this.subTitle === "In Home Date") {
      this.accountingService.getPLInHome(this.fromMonth, this.fromYear).subscribe(data => { this.profitModel = data; });
    }
    else {
      this.accountingService.getPLRec(this.fromMonth, this.fromYear).subscribe(data => { this.profitModel = data; });
    }
  }

  IHDate() {
    this.subTitle = "In Home Date";
    this.GetData();
  }

  RecDate() {
    this.subTitle = "Received Date";
    this.GetData();
  }

}
