import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from '../../Services/DashboardService';
import { AppUserAuth } from '../../security/app-user-auth';
import { Router } from '@angular/router';
import { SecurityService } from '../../security/security.service';
import { OrderService } from '../../Services/OrderService';
import * as alertify from 'alertifyjs';

@Component({
  selector: 'app-Mailer',
  templateUrl: './mailer.component.html',
  styleUrls: ['../dashboard.component.css']
})
export class MailerComponent implements OnInit {
  securityObject: AppUserAuth;
  data;
  cols: any[];
  clonedItems = {};
  loading: boolean = false;

  constructor(private router: Router, private securityService: SecurityService,
    private orderService: OrderService, public dashBoard: DashboardService) { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    if (!this.securityObject.IsAuthenticated || (this.securityObject.Role === "Design" || this.securityObject.Role === "Printer" || this.securityObject.Role === "Shipper")) { this.router.navigate(['../../']) };
    this.dashBoard.getDashboardMailer().subscribe(data => { this.data = data; }, error => { alertify.error(error.message) });
    this.cols = [
      { field: 'Status', header: 'Status' },
      { field: 'ShipDate', header: 'Ship Date' },
      { field: 'InHomeDate', header: 'In Home Date' },
      { field: 'OrderID', header: 'Order #' },
      { field: 'ItemNumber', header: 'Item #' },
      { field: 'PieceType', header: 'Item' },
      { field: 'Template', header: 'Template' },
      { field: 'Company', header: 'Company' },
      { field: 'State', header: 'State' },
      { field: 'TNumber', header: 'T #' },
      { field: 'Size', header: 'Size' },
      { field: 'Quantity', header: 'Quantity' },
      { field: 'Notes', header: 'Notes' }
    ];
  }

  showTemplate(id) {
    this.orderService.GetTemplateImage(id).subscribe(data => {
      let blob = data;
      let url = window.URL.createObjectURL(blob);
      let pwa = window.open(url);
      if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert('Please disable your Pop-up blocker and try again.');
      }
    }, (error => { alertify.error('Not Found') })
    )
  };

  onEditInit(event): void { this.clonedItems = { ...event.data }; }

  onEditCancel(event): void {
    var y = this.data.findIndex(c => c.SID === event.data.SID);
    this.data[y] = this.clonedItems;
    alertify.warning(event.field + " update cancelled");
  }

  onEditComplete(event): void {
    var y = this.data.findIndex(c => c.SID === event.data.SID);
    if (event.field === 'Status') {
      if (!isNaN(event.data.Status)) {
        this.dashBoard.updateStatus(event.data.SID, event.data.Status)
          .subscribe(data => {
            alertify.success("updated " + event.field);
            if (event.data.Status === "62") this.data[y].Status = 'Printed';
            if (event.data.Status === "44") this.data[y].Status = 'Mailed';
          },
            error => {
              alertify.error("Error: " + event.field + " not updated");
              this.data[y] = this.clonedItems;
            });
      }
      if (event.field === 'Notes') {
        this.dashBoard.updateNotes(event.data.SID, event.data.Notes)
          .subscribe(data => { alertify.success("updated " + event.field); },
            error => {
              alertify.error("Error: " + event.field + " not updated");
              this.data[y] = this.clonedItems;
            });
      }
    }
  }
}



