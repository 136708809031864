import { Component, OnInit } from '@angular/core';
import { AppointmentService } from '../Services/AppointmentService';
import { CustomersService } from '../Services/CustomerService';
import { DashboardService } from '../Services/DashboardService';
import { Router } from '@angular/router';
import { SecurityService } from '../security/security.service';
import { AppUserAuth } from '../security/app-user-auth';
import { Appoints } from '../_interfaces/Order';
import { SelectItem } from 'primeng/api';
import { AppPage } from '../../../e2e/app.po';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
  securityObject: AppUserAuth;
  date = new Date();
  longMonth;
  longYear;
  lastDay;
  firstDay;
  day: any[] = [];
  day0: SelectItem[];
  day1: SelectItem[];
  day2: SelectItem[];
  day3: SelectItem[];
  day4: SelectItem[];
  day5: SelectItem[];
  day6: SelectItem[];
  day7: SelectItem[];
  day8: SelectItem[];
  day9: SelectItem[];
  day10: SelectItem[];
  day11: SelectItem[];
  day12: SelectItem[];
  day13: SelectItem[];
  day14: SelectItem[];
  day15: SelectItem[];
  day16: SelectItem[];
  day17: SelectItem[];
  day18: SelectItem[];
  day19: SelectItem[];
  day20: SelectItem[];
  day21: SelectItem[];
  day22: SelectItem[];
  day23: SelectItem[];
  day24: SelectItem[];
  day25: SelectItem[];
  day26: SelectItem[];
  day27: SelectItem[];
  day28: SelectItem[];
  day29: SelectItem[];
  day30: SelectItem[];
  day31: SelectItem[];
  day32: SelectItem[];
  day33: SelectItem[];
  day34: SelectItem[];
  day35: SelectItem[];
  day36: SelectItem[];
  day37: SelectItem[];
  day38: SelectItem[];
  day39: SelectItem[];
  day40: SelectItem[];
  day41: SelectItem[];
  display: boolean = false;
  selectedApp: Appoints = new Appoints();
  editApp: Appoints = new Appoints();
  customerDropDown;
  constructor(private router: Router, private securityService: SecurityService,
    public dashboardService: DashboardService, public customerService: CustomersService, private service: AppointmentService) { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    securityObject: AppUserAuth;
    if (!this.securityObject.IsAuthenticated) { this.router.navigate(['../../']) };
    this.makeCalendar();
    this.customerService.getCustomerActiveLeadFilter().subscribe(data => {
      this.customerDropDown = data;
    });
    this.dashboardService.getSalesPersonFilter();
  }

  NextMonth() {
    if (this.date.getMonth() == 11) { this.date = new Date(this.date.getFullYear() + 1, 0, 1); }
    else { this.date = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 1); }
    this.longMonth = this.date.toLocaleString('en-us', { month: 'long' });
    this.longYear = this.date.getFullYear();
    this.makeCalendar();
  }

  PrevMonth() {
    if (this.date.getMonth() == 0) { this.date = new Date(this.date.getFullYear() - 1, 11, 1); }
    else {
      if (this.date.getMonth() == 0) { this.date = new Date(this.date.getFullYear() - 1, this.date.getMonth() - 1, 1); }
      else { this.date = new Date(this.date.getFullYear(), this.date.getMonth() - 1, 1); }
    }
    this.longMonth = this.date.toLocaleString('en-us', { month: 'long' });
    this.longYear = this.date.getFullYear();
    this.makeCalendar();
  }

  makeCalendar() {
    this.day = [];
    this.longMonth = this.date.toLocaleString('en-us', { month: 'long' });
    this.longYear = this.date.getFullYear();
    let m = this.date.getMonth();
    let y = this.date.getFullYear();
    this.firstDay = new Date(y, m, 1).getDay();
    this.lastDay = new Date(y, m + 1, 0).getDate();
    this.day[this.firstDay] = 1;
    let x = 2;
    for (var i = this.firstDay + 1; x <= this.lastDay; i++) { this.day[i] = x++; }
    this.getAppointments();
  }

  getAppointments() {
    this.day0 = [];
    this.day1 = [];
    this.day2 = [];
    this.day3 = [];
    this.day4 = [];
    this.day5 = [];
    this.day6 = [];
    this.day7 = [];
    this.day8 = [];
    this.day9 = [];
    this.day10 = [];
    this.day11 = [];
    this.day12 = [];
    this.day13 = [];
    this.day14 = [];
    this.day15 = [];
    this.day16 = [];
    this.day17 = [];
    this.day18 = [];
    this.day19 = [];
    this.day20 = [];
    this.day21 = [];
    this.day22 = [];
    this.day23 = [];
    this.day24 = [];
    this.day25 = [];
    this.day26 = [];
    this.day27 = [];
    this.day28 = [];
    this.day29 = [];
    this.day30 = [];
    this.day31 = [];
    this.day32 = [];
    this.day33 = [];
    this.day34 = [];
    this.day35 = [];
    this.day36 = [];
    this.day37 = [];
    this.day38 = [];
    this.day39 = [];
    this.day40 = [];
    this.day41 = [];
    let m = this.date.getMonth() + 1;
    let y = this.date.getFullYear();
    this.service.getAppointments(m, y).subscribe((data: any[]) => {
      for (let x = 0; x < data.length; x++) {
        let dm = +data[x].Appointment.substr(5, 2);
        let dy = +data[x].Appointment.substring(0, 4);
        if (dm == m && dy === y) {
          let ta = data[x].Appointment.substr(11, 5);
          let tt = +ta.substr(0, 2);
          tt = tt - 5;
          let tm = tt > 11 ? 'p' : 'a';
          tt = tt % 12;
          ta = tt.toString() + ta.substr(2) + tm;
          switch (+data[x].Appointment.substr(8, 2) + this.firstDay - 1) {
            case 0: this.day0.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 1: this.day1.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 2: this.day2.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 3: this.day3.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 4: this.day4.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 5: this.day5.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 6: this.day6.push({ label: ta, value: data[x] }); break;
            case 7: this.day7.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 8: this.day8.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 9: this.day9.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 10: this.day10.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 11: this.day11.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 12: this.day12.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 13: this.day13.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 14: this.day14.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 15: this.day15.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 16: this.day16.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 17: this.day17.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 18: this.day18.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 19: this.day19.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 20: this.day20.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 21: this.day21.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 22: this.day22.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 23: this.day23.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 24: this.day24.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 25: this.day25.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 26: this.day26.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 27: this.day27.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 28: this.day28.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 29: this.day29.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 30: this.day30.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 31: this.day31.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 32: this.day32.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 33: this.day33.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 34: this.day34.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 35: this.day35.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 36: this.day36.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 37: this.day37.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 38: this.day38.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 39: this.day39.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 40: this.day40.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
            case 41: this.day41.push({ label: ta + ' (' + data[x].Salesperson + ') ' + data[x].Customer, value: data[x] }); break;
          }
        }
      }
    })
  }

  showDialog(x) {
    this.selectedApp = new Appoints();
    let d = new Date(x.Appointment);
    d.setHours(d.getHours() - 5);
    this.selectedApp = cloneDeep(x);
    this.selectedApp.Appointment = new Date(d);
    this.display = true;
  }

  AddNewApp() { this.ClearAdd(); this.display = true }

  ClearAdd() {
    this.selectedApp = new Appoints();
    let x = new Date(); x.setHours(9);
    x.setMinutes(0);
    this.selectedApp.Appointment = x;
    this.display = false;
  }

  SaveApp() {
    if (this.selectedApp.ID > 0) { this.service.updateAppointment(this.selectedApp).subscribe(() => { this.getAppointments(); this.display = false; }); }
    else { this.service.addAppointment(this.selectedApp).subscribe(() => { this.getAppointments(); this.display = false; }); }
  }

  DeleteApp() {
    this.service.deleteAppointment(this.selectedApp.ID).subscribe(() => { this.getAppointments(); this.display = false; })
  }
}
