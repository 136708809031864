import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppUserAuth } from './app-user-auth';
import { AppUser } from './app-user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable({ providedIn: 'root' })
export class SecurityService {
  constructor(private http: HttpClient, private router: Router) { }
  securityObject: AppUserAuth = new AppUserAuth();
  loggedIn = false;

  resetSecurityObject(): void {
    this.securityObject.UserId = 0;
    this.securityObject.UserName = "";
    this.securityObject.Role = "";
    this.securityObject.Name = "";
    this.securityObject.EMail = "";
    this.securityObject.BearerToken = "";
    this.securityObject.IsAuthenticated = false;
    this.securityObject.Claims = [];

    localStorage.removeItem("bearerToken");
  }

  login(entity: AppUser): Observable<AppUserAuth> {
    this.resetSecurityObject();   // Initialize security object

    return this.http.post<AppUserAuth>('/api/Security/' + 'login', entity, httpOptions).pipe(
      tap(resp => {
        // Use object assign to update the current object
        // NOTE: Don't create a new AppUserAuth object because that destroys all references to object
        Object.assign(this.securityObject, resp);
        localStorage.setItem("bearerToken", this.securityObject.BearerToken); // Store into local storage
        this.loggedIn = true;
      }));
  }

  logout(): void {
    this.resetSecurityObject();
    this.router.navigateByUrl("/login");
  }

  //private isClaimValid(claimType: string): boolean {
  //  let ret: boolean = false;
  //  let auth: AppUserAuth = null;
  //  let claimValue: string = '';

  //  auth = this.securityObject; // Retrieve security object
  //  if (auth) {
  //    // See if the claim type has a value
  //    // *hasClaim="'claimType:value'"
  //    if (claimType.indexOf(":") >= 0) {
  //      let words: string[] = claimType.split(":");
  //      claimType = words[0].toLowerCase();
  //      claimValue = words[1];
  //    }
  //    else {
  //      claimType = claimType.toLowerCase();
  //      claimValue = claimValue ? claimValue : "true";   // Either get the claim value, or assume 'true'
  //    }
  //    ret = auth.Claims.find(  // Attempt to find the claim
  //      c => c.claimType.toLowerCase() == claimType
  //        && c.claimValue == claimValue) != null;
  //  }
  //  return ret;
  //}

  //hasClaim(claimType: any): boolean {
  //  return this.isClaimValid(claimType);
  //}

  hasClaim(claimType: any): boolean {
    for (let i = 0; i < this.securityObject.Claims.length; i++) {
      if (this.securityObject.Claims[i].claimType === claimType) { return true; }
      else { return false; }
    }
  }

}
