import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SecurityService } from '../../security/security.service';
import { AppUserAuth } from '../../security/app-user-auth';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { NewSalesDataGroup } from '../../_interfaces/NewOrderGroup';
import { AccountingService } from '../../Services/AccountingService';
import { MaintenanceService } from '../../Services/MaintenanceService';


@Component({
  selector: 'app-sales-data',
  templateUrl: './sales-data.component.html',
  styleUrls: ['./sales-data.component.css']
})
export class SalesDataComponent implements OnInit {

  securityObject: AppUserAuth;
  data;
  rowGroupMetadata: any;
  loading: boolean = false;
  year: number = new Date().getFullYear();
  Years: number[] = [this.year];
  totalRevenue: number = 0;
  totalQuantity: number = 0;
  totalGrossMargin: number = 0;
  totalClients: number = 0;
  totaRepeat: number = 0;
  totalNew: number = 0;
  totalOrders: number = 0;
  cols;
  groupTotals: NewSalesDataGroup[] = [];
  subTitle = "In Home Date";

  constructor(private router: Router, private securityService: SecurityService,
    private accountingService: AccountingService, private maintenanceService: MaintenanceService) { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    if (!this.securityObject.IsAuthenticated) { this.router.navigate(['../../']) };
    this.Years[0] = this.year;
    for (let i = this.year - 1; i > 2012; i--) { this.Years.push(i); }
    this.cols = [
      { field: 'M', header: 'Month' },
      { field: 'Quantity', header: 'Quantity' },
      { field: 'Revenue', header: 'Revenue' },
      { field: 'GrossMargin', header: 'Gross Margin % ' },
      { field: 'NumberClients', header: '# of Clients' },
      { field: 'NumberRepeat', header: '# of Repeat Cust.' },
      { field: 'NumberNew', header: '# of New Cust.' },
      { field: 'NewOrders', header: 'New Orders' },
      { field: 'SalesPerson', header: 'Sales Person' }

    ];
    this.getData(this.year);
  }

  getData(y) {
    this.loading = true;
    this.groupTotals = [];
    if (this.subTitle !== "Received Date") {
      this.accountingService.getSalesDataInHome(y).subscribe(data => {
        this.data = data;
        var distinct = this.data.map(item => item.M).filter((value, index, self) => self.indexOf(value) === index)
        for (let i = 0; i < distinct.length; i++) {
          let g = { M: distinct[i], Q: 0, R: 0, C: 0, RC: 0, NC: 0, O: 0, GM: 0 };
          var a = this.data.filter(d => d.M === distinct[i]);
          for (let j = 0; j < a.length; j++) {
            g.Q += a[j].Quantity;
            g.R += a[j].Revenue;
            g.RC += a[j].NumberRepeat;
            g.NC += a[j].NumberNew;
            g.O += a[j].NewOrders;
            g.GM += a[j].GrossMargin;
            g.C += a[j].NumberClients;
          }
          g.GM = g.GM / a.length;
         
          this.groupTotals.push(g);
        }
        this.getSum(data);
        this.loading = false;
      });
    }
    else {
      this.accountingService.getSalesDataRec(y).subscribe(data => {
        this.data = data;
        var distinct = this.data.map(item => item.M).filter((value, index, self) => self.indexOf(value) === index)
        for (let i = 0; i < distinct.length; i++) {
          let g = { M: distinct[i], Q: 0, R: 0, C: 0, RC: 0, NC: 0, O: 0, GM: 0 };
          var a = this.data.filter(d => d.M === distinct[i]);
          for (let j = 0; j < a.length; j++) {
            g.Q += a[j].Quantity;
            g.R += a[j].Revenue;
            g.RC += a[j].NumberRepeat;
            g.NC += a[j].NumberNew;
            g.O += a[j].NewOrders;
            g.GM += a[j].GrossMargin;
            g.C += a[j].NumberClients;
          }
          g.GM = g.GM / a.length;
        
          this.groupTotals.push(g);
        }
        this.getSum(data);
        this.loading = false;
      });
    }
  }

  onSort() { this.updateRowGroupMetaData(); }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.data) {
      for (let i = 0; i < this.data.length; i++) {
        let rowData = this.data[i];
        let M = rowData.M;
        if (i == 0) { this.rowGroupMetadata[M] = { index: 0, size: 1 }; }
        else {
          let previousRowData = this.data[i - 1];
          let previousRowGroup = previousRowData.M;
          if (M === previousRowGroup) this.rowGroupMetadata[M].size++;
          else this.rowGroupMetadata[M] = { index: i, size: 1 };
        }
      }
    }
  }

  getGroupTotals(d, x) {
    var a = this.groupTotals.filter(i => i.M === d);
    if (x === 'Q') return a[0].Q;
    if (x === 'R') return a[0].R;
    if (x === 'C') return a[0].C;
    if (x === 'RC') return a[0].RC;
    if (x === 'NC') return a[0].NC;
    if (x === 'O') return a[0].O;
    if (x === 'GM') return a[0].GM;
  }

  getSum(data) {
    this.totalRevenue = 0;
    this.totalQuantity = 0;
    this.totalGrossMargin = 0;
    this.totalClients = 0;
    this.totaRepeat = 0;
    this.totalNew = 0;
    this.totalOrders = 0;

    for (let x = 0; x < data.length; x++) {
      this.totalRevenue += data[x]['Revenue'];
      this.totalQuantity += data[x]['Quantity'];
      this.totalGrossMargin += data[x]['GrossMargin'];
      this.totaRepeat += data[x]['NumberRepeat'];
      this.totalNew += data[x]['NumberNew'];
      this.totalOrders += data[x]['NewOrders'];
      this.totalClients += data[x]['NumberClients'];
    }
    this.totalGrossMargin = this.totalGrossMargin / data.length;

    //this.AvgPieces = this.totalSales / this.totalQuantity;
  }

  onFilter(event, dt) {
    if (dt.filteredValue !== null) { this.getSum(dt.filteredValue); }
    else { this.getSum(this.data) }
  }

  getFilterChange() { this.getData(this.year); }

  IHDate() {
    //this.cols[1].header = "In Home Date";
    this.subTitle = "In Home Date";
    this.getData(this.year);
  }

  RecDate() {
    //this.cols[1].header = "Received Date";
    this.subTitle = "Received Date";
    this.getData(this.year);
  }
}



