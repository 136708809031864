import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../Services/DashboardService';
import { AppUserAuth } from '../../security/app-user-auth';
import { Router } from '@angular/router';
import { SecurityService } from '../../security/security.service';
import { OrderService } from '../../Services/OrderService';
import { Item, ItemDisplay } from '../../_interfaces/Order';

@Component({
  selector: 'app-dso',
  templateUrl: './dsoinprogress.component.html',
  styleUrls: ['../dashboard.component.css']
})
export class DsoInProgressComponent implements OnInit {
  securityObject: AppUserAuth;
  data;
  cols: any[];
  groupName: string;
  ctcNames: any = { AcctMgr: '', Salesperson: '', AcctPhone: '', SalesPhone: '' };
  displayDSO: boolean = false;
  headerDisplay: string;
  status: string;
  dataI;

  constructor(private router: Router, private securityService: SecurityService,
    private orderService: OrderService, public dashBoard: DashboardService) { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    if (this.securityObject.Role === "DSO") {
      this.dashBoard.getDashboardDSOInProgress(this.securityObject.Name).subscribe(data => { this.data = data; });
      this.dashBoard.getDSOCtcs(this.securityObject.Name).subscribe(data => { this.ctcNames = data; });
    }
    else { this.router.navigate(['../../']) };
    this.groupName = this.securityObject.Name;
    this.cols = [
      { field: 'ItemNumber', header: 'Item #' },
      { field: 'Size', header: 'Size' },
      { field: 'Template', header: 'Template' },
      { field: 'Company', header: 'Location' },
      { field: 'PrintDate', header: 'Print Date' },
      { field: 'ShipDate', header: 'Ship Date' },
      { field: 'InHomeDate', header: 'In Home Date' },
      { field: 'Quantity', header: 'Quantity' },
      //{ field: 'Revenue', header: 'Revenue Received' },
      { field: 'TrackingNumber', header: 'Tracking Number' },
      { field: 'TargetNumber', header: 'TargetNumber' }
    ];
  }

  showTemplate(id) {
    this.orderService.GetTemplateImage(id).subscribe(data => {
      let blob = data;
      let url = window.URL.createObjectURL(blob);
      let pwa = window.open(url);
      if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert('Please disable your Pop-up blocker and try again.');
      }
    });

  };
}
