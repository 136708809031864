import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from '../../security/security.service';
import { DashboardService } from '../../Services/DashboardService';
import { AccountingService } from '../../Services/AccountingService';
import { AppUserAuth } from '../../security/app-user-auth';
import { IRevQty, ICount, customData, customDataCount, customDataGR } from '../../_interfaces/Order';

@Component({
  selector: 'app-custom-sales-rpt',
  templateUrl: './custom-sales-rpt.component.html',
  styleUrls: ['./custom-sales-rpt.component.css']
})
export class CustomSalesRptComponent implements OnInit {
  securityObject: AppUserAuth;
  loading: boolean = false;
  year: number = new Date().getFullYear();
  years: number[] = [this.year];
  oData: IRevQty = new IRevQty;
  gRev: IRevQty = new IRevQty;
  oRev: IRevQty = new IRevQty;

  data;
  countData;
  totalCountData;
  grData;
  totalAllNewCount: number[]  =[];
  totalAllCount: number[]  =[];

  constructor(private router: Router, private securityService: SecurityService, public dashboardService: DashboardService,
    private accountingService: AccountingService) { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    if (!this.securityObject.IsAuthenticated) { this.router.navigate(['../../']); };
    this.years[0] = this.year;
    for (let i = this.year - 1; i > 2022; i--) { this.years.push(i); }
    this.getData();
  }

  getData() {
    this.data = [];
    this.countData = [];
    this.grData = [];
    let x: number = 0;
    this.loading = true;
    this.accountingService.getCustomSalesRpt(this.year).subscribe((data: customData | any) => {
      this.data = this.transformData(data);
      this.accountingService.getCustomSalesNewCount(this.year).subscribe((data: customDataCount | any) => {
        this.countData = this.transformDataCount(data);
        this.totalCountData = this.transformDataTotalCount(data);

        this.totalAllCount[0] = this.totalCountData["98"].janqty + this.totalCountData["101"].janqty + this.totalCountData["0"].janqty;
        this.totalAllCount[1] = this.totalCountData["98"].febqty + this.totalCountData["101"].febqty + this.totalCountData["0"].febqty;
        this.totalAllCount[2] = this.totalCountData["98"].marqty + this.totalCountData["101"].marqty + this.totalCountData["0"].marqty;
        this.totalAllCount[3] = this.totalCountData["98"].aprqty + this.totalCountData["101"].aprqty + this.totalCountData["0"].aprqty;
        this.totalAllCount[4] = this.totalCountData["98"].mayqty + this.totalCountData["101"].mayqty + this.totalCountData["0"].mayqty;
        this.totalAllCount[5] = this.totalCountData["98"].junqty + this.totalCountData["101"].junqty + this.totalCountData["0"].junqty;
        this.totalAllCount[6] = this.totalCountData["98"].julqty + this.totalCountData["101"].julqty + this.totalCountData["0"].julqty;
        this.totalAllCount[7] = this.totalCountData["98"].augqty + this.totalCountData["101"].augqty + this.totalCountData["0"].augqty;
        this.totalAllCount[8] = this.totalCountData["98"].sepqty + this.totalCountData["101"].sepqty + this.totalCountData["0"].sepqty;
        this.totalAllCount[9] = this.totalCountData["98"].octqty + this.totalCountData["101"].octqty + this.totalCountData["0"].octqty;
        this.totalAllCount[10] = this.totalCountData["98"].novqty + this.totalCountData["101"].novqty + this.totalCountData["0"].novqty;
        this.totalAllCount[11] = this.totalCountData["98"].decqty + this.totalCountData["101"].decqty + this.totalCountData["0"].decqty;
        this.totalAllCount[12] = this.totalCountData["98"].ytdqty + this.totalCountData["101"].ytdqty + this.totalCountData["0"].ytdqty;

        this.totalAllNewCount[0] = this.countData["98"].janqty + this.countData["101"].janqty + this.countData["0"].janqty ;
        this.totalAllNewCount[1] = this.countData["98"].febqty + this.countData["101"].febqty + this.countData["0"].febqty;
        this.totalAllNewCount[2] = this.countData["98"].marqty + this.countData["101"].marqty + this.countData["0"].marqty;
        this.totalAllNewCount[3] = this.countData["98"].aprqty + this.countData["101"].aprqty + this.countData["0"].aprqty;
        this.totalAllNewCount[4] = this.countData["98"].mayqty + this.countData["101"].mayqty + this.countData["0"].mayqty;
        this.totalAllNewCount[5] = this.countData["98"].junqty + this.countData["101"].junqty + this.countData["0"].junqty;
        this.totalAllNewCount[6] = this.countData["98"].julqty + this.countData["101"].julqty + this.countData["0"].julqty;
        this.totalAllNewCount[7] = this.countData["98"].augqty + this.countData["101"].augqty + this.countData["0"].augqty;
        this.totalAllNewCount[8] = this.countData["98"].sepqty + this.countData["101"].sepqty + this.countData["0"].sepqty;
        this.totalAllNewCount[9] = this.countData["98"].octqty + this.countData["101"].octqty + this.countData["0"].octqty;
        this.totalAllNewCount[10] = this.countData["98"].novqty + this.countData["101"].novqty + this.countData["0"].novqty;
        this.totalAllNewCount[11] = this.countData["98"].decqty + this.countData["101"].decqty + this.countData["0"].decqty;
        this.totalAllNewCount[12] = this.countData["98"].ytdqty + this.countData["101"].ytdqty + this.countData["0"].ytdqty;

       
      });
      this.accountingService.getCustomSalesGR(this.year).subscribe((data: customDataGR | any) => {
        this.grData = this.transformGData(data);
      });
      this.loading = false;
    });
  }

  transformData(data: customData[]): { [key: number]: IRevQty; } {
    const result: { [key: number]: IRevQty; } = {};
    data.forEach((item) => {
      var { SalesPID, OrderMonth, TotalQuantity, TotalRevenue, Profit } = item;
      // Ensure an entry exists for the SalesPID
      if (SalesPID !== 98 && SalesPID !== 101) { SalesPID = 0; }
      if (!result[SalesPID]) { result[SalesPID] = new IRevQty(); }
      // Map OrderMonth to corresponding fields in IRevQty
      const revField = this.getMonthField(OrderMonth, 'rev');
      const qtyField = this.getMonthField(OrderMonth, 'qty');
      const profitField = this.getMonthField(OrderMonth, 'profit');
      if (revField && qtyField) {
        result[SalesPID][revField] += TotalRevenue;
        result[SalesPID][qtyField] += TotalQuantity;
        result[SalesPID][profitField] += Profit;
      }
      // Update YTD totals
      result[SalesPID].ytdrev += TotalRevenue;
      result[SalesPID].ytdqty += TotalQuantity;
      result[SalesPID].ytdprofit += Profit;
    });
    return result;
  }

  transformGData(data: customData[]): { [key: number]: IRevQty; } {
    const result: { [key: number]: IRevQty; } = {};
    data.forEach((item) => {
      var { SalesPID, OrderMonth, TotalRevenue, Profit } = item;
      // Ensure an entry exists for the SalesPID
      if (SalesPID !== 0 && SalesPID !== 1) { SalesPID = 0; }
      if (!result[SalesPID]) { result[SalesPID] = new IRevQty(); }
      // Map OrderMonth to corresponding fields in IRevQty
      const revField = this.getMonthField(OrderMonth, 'rev');
      const profitField = this.getMonthField(OrderMonth, 'profit');
      if (revField &&profitField) {
        result[SalesPID][revField] += TotalRevenue;
        result[SalesPID][profitField] += Profit;
      }
      // Update YTD totals
      result[SalesPID].ytdrev += TotalRevenue;
      result[SalesPID].ytdprofit += Profit;
    });
    return result;
  }

  transformDataCount(data: customDataCount[]): { [key: number]: ICount; } {
    const result: { [key: number]: ICount; } = {};

    data.forEach((item) => {
      var { SalesPID, OrderMonth, NewCount } = item;

      // Ensure an entry exists for the SalesPID
      if (SalesPID !== 98 && SalesPID !== 101) { SalesPID = 0; }
      if (!result[SalesPID]) { result[SalesPID] = new ICount(); }

      // Map OrderMonth to corresponding fields in IRevQty
      const countField = this.getMonthField(OrderMonth, 'qty');

      if (countField) { result[SalesPID][countField] += NewCount; }

      // Update YTD totals
      result[SalesPID].ytdqty += NewCount;
    });
    return result;
  }

  transformDataTotalCount(data: customDataCount[]): { [key: number]: ICount; } {
    const result: { [key: number]: ICount; } = {};

    data.forEach((item) => {
      var { SalesPID, OrderMonth, TotalOrders } = item;

      // Ensure an entry exists for the SalesPID
      if (SalesPID !== 98 && SalesPID !== 101) { SalesPID = 0; }
      if (!result[SalesPID]) { result[SalesPID] = new ICount(); }

      // Map OrderMonth to corresponding fields in IRevQty
      const countField = this.getMonthField(OrderMonth, 'qty');

      if (countField) { result[SalesPID][countField] += TotalOrders; }

      // Update YTD totals
      result[SalesPID].ytdqty += TotalOrders;
    });
    return result;
  }


  // Helper function to map month numbers to field names
  getMonthField(month: number, type: 'rev' | 'qty' | 'profit'): string | null {
    const monthMap: { [key: number]: string; } = {
      1: 'jan',
      2: 'feb',
      3: 'mar',
      4: 'apr',
      5: 'may',
      6: 'jun',
      7: 'jul',
      8: 'aug',
      9: 'sep',
      10: 'oct',
      11: 'nov',
      12: 'dec',
    };

    const prefix = monthMap[month];
    return prefix ? `${prefix}${type}` : null;
  }
}

