import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SecurityService } from '../../security/security.service';
import { AppUserAuth } from '../../security/app-user-auth';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { AccountingService } from '../../Services/AccountingService';

@Component({
  selector: 'app-receivables',
  templateUrl: './receivables.component.html',
  styleUrls: ['./receivables.component.css']
})
export class ReceivablesComponent implements OnInit {
  securityObject: AppUserAuth;
  data;
  filteredValues;
  loading: boolean = false;
  Year: number = new Date().getFullYear();
  Years: number[] = [this.Year];
  Month: number = new Date().getMonth();
  Months = [
    {
      "key": 1,
      "value": "January"
    },
    {
      "key": 2,
      "value": "February"
    },
    {
      "key": 3,
      "value": "March"
    },
    {
      "key": 4,
      "value": "April"
    },
    {
      "key": 5,
      "value": "May"
    },
    {
      "key": 6,
      "value": "June"
    },
    {
      "key": 7,
      "value": "July"
    },
    {
      "key": 8,
      "value": "August"
    },
    {
      "key": 9,
      "value": "September"
    },
    {
      "key": 10,
      "value": "October"
    },
    {
      "key": 11,
      "value": "November"
    },
    {
      "key": 12,
      "value": "December"
    }
  ];
  cols;
  colTotal;
  constructor(private router: Router, private securityService: SecurityService,
    private accountingService: AccountingService) { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    if (!this.securityObject.IsAuthenticated) { this.router.navigate(['../../']) };
    this.Years[0] = this.Year;
    for (let i = this.Year - 1; i > 2012; i--) { this.Years.push(i); }
    this.getData(this.Month, this.Year);
    this.cols = [
      { field: 'Paid', header: 'Status' },
      { field: 'OrderID', header: 'Order #' },
      { field: 'OrderDate', header: 'Order Date' },
      { field: 'Customer', header: 'Customer' },
      { field: 'SalesPerson', header: 'Salesperson' },
      { field: 'Quantity', header: 'Quantity' },
      { field: 'Total', header: 'Total' },
      { field: 'RecAmount', header: 'Rec Amount' },
      { field: 'Balance', header: 'Balance' }
    ]
    this.colTotal = [
      { field: 'Paid', Total: '' },
      { field: 'OrderID', Total: '' },
      { field: 'OrderDate', Total: '' },
      { field: 'Customer', Total: '' },
      { field: 'SalesPerson', Total: '' },
      { field: 'Quantity', Total: 0 },
      { field: 'Total', Total: 0 },
      { field: 'RecAmount', Total: 0 },
      { field: 'Balance', Total: 0 }
    ]
  }

  getData(m, y) {
    this.loading = true;
    this.accountingService.getReceivables(m, y).subscribe(data => {
      this.data = data;
      this.getSum()
      this.loading = false;
    });
  }

  getSum() {

    for (let i = 0; i < this.colTotal.length; i++) {
      var fieldSum = 0;
      if (this.colTotal[i].Total !== '') {
        for (let x = 0; x < this.data.length; x++) {
          fieldSum += this.data[x][this.colTotal[i]['field']];
        }
        this.colTotal[i]['Total'] = fieldSum;
      }
    }
  }

  onFilter(event, dt) {
    this.filteredValues = event.filteredValue;
    for (let i = 0; i < this.colTotal.length; i++) {
      var fieldSum = 0;
      if (this.colTotal[i].Total !== '') {
        for (let x = 0; x < this.filteredValues.length; x++) {
          fieldSum += this.filteredValues[x][this.colTotal[i]['field']];
        }
        this.colTotal[i]['Total'] = fieldSum;
      }
    }
  }
}

