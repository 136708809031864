import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from '../../Services/DashboardService';
import { AppUserAuth } from '../../security/app-user-auth';
import { Router } from '@angular/router';
import { SecurityService } from '../../security/security.service';
import { OrderService } from '../../Services/OrderService';
import * as alertify from 'alertifyjs';

@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['../dashboard.component.css']
})
export class PrinterComponent implements OnInit {
  securityObject: AppUserAuth;
  data;
  cols: any[];
  clonedItems = {};

  constructor(private router: Router, private securityService: SecurityService,
    private orderService: OrderService, public dashBoard: DashboardService) { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    if (!this.securityObject.IsAuthenticated || (this.securityObject.Role === "Design" || this.securityObject.Role === "Mailer" || this.securityObject.Role === "Shipper")) { this.router.navigate(['../../']) };
    if (this.securityObject.Role === "Printer") this.dashBoard.getDashboardPrinter(this.securityObject.EMail).subscribe(data => { this.data = data; }, error => { alertify.error(error.message) });
    else if (this.securityObject.Role === "MagPrinter") this.dashBoard.getDashboardMagPrinter().subscribe(data => { this.data = data; }, error => { alertify.error(error.message) });
    else this.dashBoard.getDashboardAllPrinter().subscribe(data => { this.data = data; }, error => { alertify.error(error.message) });
    this.cols = [
      { field: 'Status', header: 'Status' },
      { field: 'ItemNumber', header: 'Item #' },
      { field: 'Size', header: 'Size' },
      { field: 'PieceType', header: 'Item' },
      { field: 'Template', header: 'Template' },
      { field: 'Company', header: 'Company' },
      { field: 'Quantity', header: 'Quantity' },
      //{ field: 'ShipToMailer', header: 'Ship To Mailer' },
      { field: 'ShipDate', header: 'Ship Date' },
      { field: 'InHomeDate', header: 'In Home Date' },
      { field: 'PrinterCost', header: 'Printer Cost' },
      { field: 'ExtraCopies', header: 'Extra Copies' },
      { field: 'LayoutNumber', header: 'PressSheet' },
      { field: 'Notes', header: 'Notes' }
    ];
  }

  showTemplate(id) {
    this.orderService.GetTemplateImage(id).subscribe(data => {
      let blob = data;
      let url = window.URL.createObjectURL(blob);
      let pwa = window.open(url);
      if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert('Please disable your Pop-up blocker and try again.');
      }
    }, (error => { alertify.error('Not Found') })
    )
  };

  onEditInit(event): void { this.clonedItems = { ...event.data }; }

  onEditCancel(event): void {
    var y = this.data.findIndex(c => c.SID === event.data.SID);
    this.data[y] = this.clonedItems;
    alertify.warning(event.field + " update cancelled");
  }

  onEditComplete(event): void {
    var y = this.data.findIndex(c => c.SID === event.data.SID);
    if (event.field === 'Status') {
      if (!isNaN(event.data.Status)) {
        this.dashBoard.updateStatus(event.data.SID, event.data.Status)
          .subscribe(data => {
            alertify.success("updated " + event.field);
            if (event.data.Status === "40") this.data[y].Status = 'Approved for Print';
            if (event.data.Status === "41") this.data[y].Status = 'In Transit';
          },
            error => {
              alertify.error("Error: " + event.field + " not updated");
              this.data[y] = this.clonedItems;
            });
      }
      if (event.field === 'PrinterCost') {
        this.dashBoard.updatePrinterCost(event.data.SID, event.data.PrinterCost)
          .subscribe(data => { alertify.success("updated " + event.field); },
            error => {
              alertify.error("Error: " + event.field + " not updated");
              this.data[y] = this.clonedItems;
            });
      }
    }
    if (event.field === 'Notes') {
      this.dashBoard.updateNotes(event.data.SID, event.data.Notes)
        .subscribe(data => { alertify.success("updated " + event.field); },
          error => {
            alertify.error("Error: " + event.field + " not updated");
            this.data[y] = this.clonedItems;
          });
    }
    if (event.field === 'LayoutNumber') {
      this.dashBoard.updatePressSheet(event.data.SID, event.data.LayoutNumber)
        .subscribe(data => { alertify.success("updated " + event.field); },
          error => {
            alertify.error("Error: " + event.field + " not updated");
            this.data[y] = this.clonedItems;
          });
    }
  }
}
