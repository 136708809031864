import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DashboardService } from '../../Services/DashboardService';
import { OrderService } from '../../Services/OrderService';
import { AppUserAuth } from '../../security/app-user-auth';
import { Router } from '@angular/router';
import { SecurityService } from '../../security/security.service';
import { Table } from 'primeng/table';
import * as alertify from 'alertifyjs';

@Component({
  selector: 'app-projectManager',
  templateUrl: './projectManager.component.html',
  styleUrls: ['../dashboard.component.css']
})
export class ProjectManagerComponent implements OnInit {
  securityObject: AppUserAuth;
  data;
  cols: any[];
  _selectedColumns: any[];
  projectManagerColumns;
  loading: boolean = true;
  ItemNumber;
  @ViewChild('dt') table: Table;
  filteredValues;

  constructor(private router: Router, private securityService: SecurityService, public dashBoard: DashboardService,
    private orderService: OrderService) { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    if (!this.securityObject.IsAuthenticated || (this.securityObject.Role === "Design" || this.securityObject.Role === "Printer" || this.securityObject.Role === "Mailer" || this.securityObject.Role === "Shipper")) { this.router.navigate(['../../']) };
    //this.table.restoreState();
    //this.table.reset();
    if (this.securityObject.Role === "Sales") { this.dashBoard.getDashboardSales(this.securityObject.UserName).subscribe(data => { this.data = data; this.filteredValues = data; }, error => { alertify.error(error.message) }); }
    else {
      this.dashBoard.getDashboard().subscribe(data => { this.data = data; this.getSum(); }, error => { alertify.error(error.message) });
 
    }
    this.cols = [
      { field: 'OrderID', header: 'Order #', total: '' },
      { field: 'ItemNumber', header: 'Item #', total: '' },
      { field: 'PieceType', header: 'Item', total: '' },
      { field: 'Salesperson', header: 'Salesperson', total: '' },
      { field: 'AcctMgr', header: 'Acct Mgr', total: '' },
      { field: 'Template', header: 'Template', total: '' },
      { field: 'Company', header: 'Company', total: '' },
      { field: 'InHomeDate', header: 'In Home Date', total: '' },
      { field: 'ShipDate', header: 'Ship Date', total: '' },
      { field: 'PrintDate', header: 'Print Date', total: '' },
      { field: 'LayoutNumber', header: 'Press Sheet', total: '' },

      { field: 'Quantity', header: 'Quantity', total: 0 },
      { field: 'Revenue', header: 'Revenue', total: 0 },
      { field: 'pays', header: 'Paid Amt', total: '' }, //added to show paid amt
      { field: 'TNumber', header: 'T #', total: '' },
      { field: 'Contact', header: 'Contact', total: '' },
      { field: 'Status', header: 'Status', total: '' },
      { field: 'TrackingNumber', header: 'Tracking #', total: '' },
      { field: 'Size', header: 'Size', total: '' },
      //{ field: 'PrintDate', header: 'Upload to Printer' },
      { field: 'USPSDate', header: 'USPS Date', total: '' },
      { field: 'ShipToMailer', header: 'Ship To Mailer', total: '' },
      { field: 'ReadyToShip', header: 'QC Date', total: '' },
      { field: 'Paid', header: 'Paid', total: '' },
      { field: 'BColor', header: '', total: '' },
      { field: 'OID', header: '', total: '' },
      { field: 'SID', header: '', total: '' },
      { field: 'CustID', header: '', total: '' },
      { field: 'TemplateFileID', header: '', total: '' }
    ];
    this.cols = this.cols.filter(col => col.header !== '')
    this._selectedColumns = this.cols;
    this.projectManagerColumns = JSON.parse(window.localStorage.getItem('projectManagerColumns'));
    if (this.projectManagerColumns === null) { window.localStorage.setItem('projectManagerColumns', JSON.stringify(this._selectedColumns)); }
    else { this._selectedColumns = this.projectManagerColumns }
   
   
    this.loading = false;
  }

  @Input() get selectedColumns(): any[] {
    var x = this._selectedColumns;
    window.localStorage.setItem('projectManagerColumns', JSON.stringify(x));
    return x;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    window.localStorage.setItem('projectManagerColumns', JSON.stringify(this.selectedColumns));
  }

  saveReorderedColumns(event: any) {
    window.localStorage.setItem('projectManagerColumns', JSON.stringify(this.selectedColumns));
  }

  showTemplate(id) {
    this.orderService.GetTemplateImage(id).subscribe(data => {
      let blob = data;
      let url = window.URL.createObjectURL(blob);
      let pwa = window.open(url);
      if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert('Please disable your Pop-up blocker and try again.');
      }
    }, (error => { alertify.error('Not Found') })
    )
  };

  getRevStatus(data) {
    return data['Revenue'] > data['pays'] ? 'red' : 'inherit';
  }

  getSum() {
    for (let i = 0; i < this.selectedColumns.length; i++) {
      var fieldSum = 0;
      if (this.selectedColumns[i].total !== '') {
        for (let x = 0; x < this.data.length; x++) {
          fieldSum += this.data[x][this.selectedColumns[i]['field']];
        }
        this.selectedColumns[i]['total'] = fieldSum;
      }
    }
  }

  onFilter(event, dt) {
    this.filteredValues = event.filteredValue;
    for (let i = 0; i < this.selectedColumns.length; i++) {
      var fieldSum = 0;
      if (this.selectedColumns[i].total !== 0) {
        for (let x = 0; x < this.filteredValues.length; x++) {
          fieldSum += this.filteredValues[x][this.selectedColumns[i]['field']];
        }
        this.selectedColumns[i]['total'] = fieldSum;
      }
    }
  }

  //reset(dt: any) {
  //  dt.filteredValue = null;
  //  dt.reset();
  //}
}
