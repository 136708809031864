import { Component, OnInit } from '@angular/core';
import { AppUser } from './app-user';
import { AppUserAuth } from './app-user-auth';
import { SecurityService } from './security.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from '../Services/Users';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  //styleUrls: ['../sell/sell.component.css']
})
export class LoginComponent implements OnInit {
  user: AppUser = new AppUser();
  securityObject: AppUserAuth = null;
  returnUrl: string;

  constructor(private securityService: SecurityService, private route: ActivatedRoute, private router: Router, private _userService: UsersService) { }

  ngOnInit() { this.user = new AppUser(); this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl'); }

  login() {
    this.securityService.login(this.user).subscribe(resp => {
      this.securityObject = resp;
      if (this.returnUrl) { this.router.navigateByUrl(this.returnUrl); }
      else if (this.securityObject.Role === 'Design') { this.router.navigateByUrl("/dashboard/designer"); }
      else if (this.securityObject.Role === 'Printer') { this.router.navigateByUrl("/dashboard/printer"); }
      else if (this.securityObject.Role === 'DSO') { this.router.navigateByUrl("/dashboard/dso"); }
      else if (this.securityObject.Role === 'DSOI') { this.router.navigateByUrl("/customers/campaigns/" + this.securityObject.EMail); }
      else if (this.securityObject.Role === 'MagPrinter') { this.router.navigateByUrl("/dashboard/printer"); }
      else if (this.securityObject.Role === 'Mailer') { this.router.navigateByUrl("/dashboard/mailer"); }
      else if (this.securityObject.Role === 'Shipper') { this.router.navigateByUrl("/dashboard/shipper"); }
    
    
      //    else if (this.securityObject.Role === 'SuperAdmin' || this.securityObject.Role === 'Admin' || this.securityObject.Role === 'Admin-ReadOnly' || this.securityObject.Role === 'Account Manager') { this.router.navigateByUrl("/gatherCT"); } //this.router.navigateByUrl("/dashboard/projectmanager") } // this.router.navigateByUrl("/gatherCT");  } gather
      else if (this.securityObject.Role === 'SuperAdmin' || this.securityObject.Role === 'Admin' || this.securityObject.Role === 'Admin-ReadOnly' || this.securityObject.Role === 'Account Manager') { this.router.navigateByUrl("/dashboard/projectmanager") } //this.router.navigateByUrl("/dashboard/projectmanager") } // this.router.navigateByUrl("/gatherCT");  } gather
      else { this.router.navigateByUrl("/") }
    },
      () => { this.securityObject = new AppUserAuth(); });
  }

  cancel() {
    this.user = new AppUser();
  }
}


