import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SecurityService } from '../../security/security.service';
import { AppUserAuth } from '../../security/app-user-auth';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { AccountingService } from '../../Services/AccountingService';
import { DashboardService } from '../../Services/DashboardService';
import { CustomersService } from '../../Services/CustomerService';
import { OrderService } from '../../Services/OrderService';

@Component({
  selector: 'app-customer-report',
  templateUrl: './customer-report.component.html',
  styleUrls: ['./customer-report.component.css']
})

export class CustomerReportComponent implements OnInit {
  securityObject: AppUserAuth;
  data;
  filteredValues;
  loading: boolean = false;
  sYear: number = new Date().getFullYear();
  sMonth: number = new Date().getMonth() + 1;
  eYear: number = new Date().getFullYear();
  eMonth: number = new Date().getMonth() + 1;
  Year: number = new Date().getFullYear();
  Years: number[] = [this.Year];
  Month: number = new Date().getMonth();
  Months = [
    { "key": 1, "value": "January" },
    { "key": 2, "value": "February" },
    { "key": 3, "value": "March" },
    { "key": 4, "value": "April" },
    { "key": 5, "value": "May" },
    { "key": 6, "value": "June" },
    { "key": 7, "value": "July" },
    { "key": 8, "value": "August" },
    { "key": 9, "value": "September" },
    { "key": 10, "value": "October" },
    { "key": 11, "value": "November" },
    { "key": 12, "value": "December" }
  ];
  customerDropDown;
  selectedCust;
  selectedCust2 = '';
  cols;
  ARColumns;
  colTotal;
  @ViewChild('dt') table: Table;

  constructor(private router: Router, private securityService: SecurityService,
    public dashboardService: DashboardService, private orderService: OrderService,
    private accountingService: AccountingService, private customerService: CustomersService) { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    if (!this.securityObject.IsAuthenticated) { this.router.navigate(['../../']); };
    this.customerService.getCustomerFilter().subscribe(data => {
      this.customerDropDown = data;
    });
    this.dashboardService.getSalesPersonFilter();
    this.Years[0] = this.Year;
    for (let i = this.Year - 1; i > 2012; i--) { this.Years.push(i); }
    var d = new Date().getMonth();
    if (d >= 11) { this.Years.unshift(this.Years[0] + 1); }
    this.cols = [
      { field: 'OrderID', header: 'Item #' },
      { field: 'Template', header: 'Template' },
      { field: 'InHomeDate', header: 'In Home Date' },
      { field: 'LastSeminar', header: 'Last Seminar Date' },
      { field: 'NoOfCalls', header: '# Of Calls' },
      { field: 'Quantity', header: 'Quantity' },
      { field: 'Revenue', header: 'Revenue' },
      { field: 'Rate', header: 'Rate' },
      { field: 'Profit', header: 'Profit' },
      { field: 'GrossMargin', header: 'Gross Margin' },
      { field: 'SalesPerson', header: 'Salesperson' },
    ];
    this.colTotal = [
      { field: 'OrderID', Total: '' },
      { field: 'Template', Total: '' },
      { field: 'InHomeDate', Total: '' },
      { field: 'LastSeminar', Total: '' },
      { field: 'NoOfCalls', Total: '0' },
      { field: 'Quantity', Total: '0' },
      { field: 'Revenue', Total: '0' },
      { field: 'Rate', Total: '0' },
      { field: 'Profit', Total: '0' },
      { field: 'GrossMargin', Total: '' },
      { field: 'SalesPerson', Total: '' }
    ];
  }

  getCust() {
    //   this.loading = true;
    this.accountingService.getCustRpt(this.sMonth, this.sYear, this.eMonth, this.eYear, this.selectedCust2['ID']).subscribe(data => {
      this.data = data;
      this.getSum();
      //  this.loading = false;
    });
  }

  getSum() {
    for (let i = 0; i < this.colTotal.length; i++) {
      var fieldSum = 0;
      if (this.colTotal[i].Total !== '') {
        for (let x = 0; x < this.data.length; x++) { fieldSum += this.data[x][this.colTotal[i]['field']]; }
        this.colTotal[i]['Total'] = fieldSum;
      }
    }
  }

  onFilter(event, dt) {
    this.filteredValues = event.filteredValue;
    for (let i = 0; i < this.colTotal.length; i++) {
      var fieldSum = 0;
      if (this.colTotal[i].Total !== '') {
        for (let x = 0; x < this.filteredValues.length; x++) {
          fieldSum += this.filteredValues[x][this.colTotal[i]['field']];
        }
        this.colTotal[i]['Total'] = fieldSum;
      }
    }
  }

  getColTotal(fld) {
    for (let i = 0; i < this.colTotal.length; i++) {
      if (fld === this.colTotal[i].field) return this.colTotal[i].Total;
    }
    return '';
  }

  showTemplate(id) {
    this.orderService.GetTemplateImage(id).subscribe(data => {
      let blob = data;
      let url = window.URL.createObjectURL(blob);
      let pwa = window.open(url);
      if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') { alert('Please disable your Pop-up blocker and try again.'); }
    });
  }
}
