import { Component, OnInit } from '@angular/core';
import { SecurityService } from '../security/security.service';
import { AppUserAuth } from '../security/app-user-auth';
import { Router } from '@angular/router';
import { CustomersService } from '../Services/CustomerService';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})

export class NotesComponent implements OnInit {s
  securityObject: AppUserAuth;
  data;
  cols;

  constructor(private router: Router, private custService: CustomersService,
    private securityService: SecurityService) { this.securityObject = securityService.securityObject; { } }

  ngOnInit() {
    if (!this.securityObject.IsAuthenticated) { this.router.navigate(['../']) };
    this.custService.getNotes().subscribe(data => { this.data = data });
    this.cols = [
      { field: 'nType', header: 'Type', width: '10%' },
      { field: 'DateEntered', header: 'Date Entered', width: '10%' },
      { field: 'Originator', header: 'Originator', width: '10%' },
      { field: 'Company', header: 'Company', width: '10%' },
      { field: 'Salesperson', header: 'Salesperson', width: '10%' },
      { field: 'Note', header: 'Note', width: '40%' },
      { field: 'FollowUp', header: 'Follow Up', width: '10%' },
    ];
  };
}

