import { AppUserClaim } from "./app-user-claim";

export class AppUserAuth {
  UserId: number = 0;
  UserName: string = "";
  Password: string = "";
  Role: string = "";
  EMail: string = "";
  Name: string = "";
  BearerToken: string = "";
  IsAuthenticated: boolean = false;
  Claims: AppUserClaim[] = [];
}
