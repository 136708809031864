import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable({ providedIn: 'root' })
export class UsersService {
  constructor(private http: HttpClient) { }
  private headers: HttpHeaders;

  getUsers() { return this.http.get('/api/Users'); }

  saveUser(newUser) { return this.http.put('/api/Users/' + newUser.UserId, newUser, { headers: this.headers }) }

  addUser(n) { return this.http.post('/api/Users/', n) }

  deleteUser(u) { return this.http.delete('/api/Users/' + u) }

}
