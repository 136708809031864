import { Component, OnInit } from '@angular/core';
import { SecurityService } from '../security/security.service';
import { AppUserAuth } from '../security/app-user-auth';
import { Router } from '@angular/router';
import { CustomersService } from '../Services/CustomerService';
import { OrderService } from '../Services/OrderService';

@Component({
  selector: 'app-reorder',
  templateUrl: './reorder.component.html',
  styleUrls: ['./reorder.component.css']
})
export class ReorderComponent implements OnInit {
  securityObject: AppUserAuth;
  data;
  cols;

  constructor(private router: Router, private custService: CustomersService, private orderService: OrderService,
    private securityService: SecurityService) { this.securityObject = securityService.securityObject; { } }

  ngOnInit() {
    if (!this.securityObject.IsAuthenticated) { this.router.navigate(['../']); };
    //TODO FIX THIS if (this.securityObject.Role === 'Sales') { this.custService.getReOrdersSales(this.securityObject.UserName).subscribe(data => { this.data = data }); }
    // else { this.custService.getReOrders().subscribe(data => { this.data = data; }); }
    this.custService.getReOrders().subscribe(data => { this.data = data; });
    this.cols = [
      { field: 'InHomeDate', header: 'In Home Date', width: '10%' },
      { field: 'SalesPerson', header: 'Saleperson', width: '10%' },
      { field: 'OrderID', header: 'Order ID', width: '10%' },
      { field: 'Template', header: 'Template', width: '15%' },
      { field: 'Company', header: 'Customer', width: '20%' },
      { field: 'Contact', header: 'Contact', width: '10%' },
      { field: 'Email', header: 'Email', width: '15%' },
      { field: 'Phone', header: 'Phone', width: '10%' },
      { field: 'NumberOfCalls', header: '# Calls', width: '10%' }
    ];
  };

  showTemplate(id) {
    this.orderService.GetTemplateImage(id).subscribe(data => {
      let blob = data;
      let url = window.URL.createObjectURL(blob);
      let pwa = window.open(url);
      if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert('Please disable your Pop-up blocker and try again.');
      }
    });
 
  };
}

