import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SecurityService } from '../../security/security.service';
import { AppUserAuth } from '../../security/app-user-auth';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { AccountingService } from '../../Services/AccountingService';


@Component({
  selector: 'app-commission',
  templateUrl: './commission.component.html',
  styleUrls: ['./commission.component.css']
})
export class CommissionsComponent implements OnInit {
  securityObject: AppUserAuth;
  data;
  filteredValues;
  loading: boolean = false;
  Year: number = new Date().getFullYear();
  Years: number[] = [this.Year];
  Month: number = new Date().getMonth();
  Months = [
    {
      "key": 1,
      "value": "January"
    },
    {
      "key": 2,
      "value": "February"
    },
    {
      "key": 3,
      "value": "March"
    },
    {
      "key": 4,
      "value": "April"
    },
    {
      "key": 5,
      "value": "May"
    },
    {
      "key": 6,
      "value": "June"
    },
    {
      "key": 7,
      "value": "July"
    },
    {
      "key": 8,
      "value": "August"
    },
    {
      "key": 9,
      "value": "September"
    },
    {
      "key": 10,
      "value": "October"
    },
    {
      "key": 11,
      "value": "November"
    },
    {
      "key": 12,
      "value": "December"
    }
  ];
  cols;
  colTotal;
  filteredValuesCount: number;

  constructor(private router: Router, private securityService: SecurityService,
    private accountingService: AccountingService) { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    if (!this.securityObject.IsAuthenticated) { this.router.navigate(['../../']) };
    var d = new Date();
    this.Month = d.getMonth() + 1;
    this.Years[0] = this.Year;
    for (let i = this.Year - 1; i > 2012; i--) { this.Years.push(i); }
    if (this.Month >= 11) { this.Years.unshift(this.Years[0] + 1); }
    this.getData(this.Month, this.Year);
    this.cols = [
      { field: 'RecDate', header: 'Rec Date' },
      { field: 'InHomeDate', header: 'In Home Date' },
      { field: 'Customer', header: 'Customer' },
      { field: 'OrderID', header: 'Item #' },
      { field: 'Amount', header: 'Amount' },
      { field: 'SalesPerson', header: 'Salesperson' },
      { field: 'GrossMargin', header: 'Gross Margin %' },
      { field: 'CommissionPercent', header: 'Commission %' },
      { field: 'Commission', header: 'Commission' }
    ]
    this.colTotal = [
      { field: 'RecDate', Total: '' },
      { field: 'Customer', Total: '' },
      { field: 'OrderID', Total: '' },
      { field: 'Amount', Total: 0 },
      { field: 'SalesPerson', Total: '' },
      { field: 'GrossMargin', Total: '' },
      { field: 'CommissionPercent', Total: '' },
      { field: 'Commission', Total: 0 }
    ]
  }

  getData(m, y) {
    this.loading = true;
    this.accountingService.getCommissionRpt(m, y).subscribe(data => {
      this.data = data;
      this.getSum()
      this.loading = false;
    });
  }

  getSum() {

    for (let i = 0; i < this.colTotal.length; i++) {
      var fieldSum = 0;
      if (this.colTotal[i].Total !== '') {
        for (let x = 0; x < this.data.length; x++) {
          fieldSum += this.data[x][this.colTotal[i]['field']];
        }
        this.colTotal[i]['Total'] = fieldSum;
      }
    }
    this.filteredValues = this.data;
  }

  onFilter(event, dt) {
    this.filteredValues = event.filteredValue;
    for (let i = 0; i < this.colTotal.length; i++) {
      var fieldSum = 0;
      if (this.colTotal[i].Total !== '') {
        for (let x = 0; x < this.filteredValues.length; x++) {
          fieldSum += this.filteredValues[x][this.colTotal[i]['field']];
        }
        this.colTotal[i]['Total'] = fieldSum;
      }
    }
  }
}

