import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from '../security/security.service';
import { AppUserAuth } from '../security/app-user-auth';
import { MenuItem } from 'primeng/api';
import { DashboardService } from '../Services/DashboardService';

@Component({
  selector: 'app-Dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
 
  securityObject: AppUserAuth;
  items: MenuItem[];
  selectedItem: string = '';
 
  constructor(private router: Router, private securityService: SecurityService, private dashboardService: DashboardService) { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    if (!this.securityObject.IsAuthenticated) { this.router.navigate(['../../']) };
    if (this.securityObject.Role === 'Admin' || this.securityObject.Role === 'SuperAdmin' || this.securityObject.Role === 'Account Manager' ) {
      this.items = [
        { label: 'Project Manager', icon: 'pi pi-fw pi-table', routerLink: ['projectmanager'] },
        { label: 'Design', icon: 'fa fa-camera', routerLink: ['designer'] },
        { label: 'Printer', icon: 'fa fa-print', routerLink: ['printer'] },
        { label: 'Mailer', icon: 'fa fa-envelope', routerLink: ['mailer'] },
        { label: 'Shipper', icon: 'fa fa-truck', routerLink: ['shipper'] },
        { label: 'Pending Completion', icon: 'fa fa-check-square', routerLink: ['pending'] },
        { label: 'Call Tracking', icon: 'fa fa-phone', routerLink: ['calltracking'] },
        { label: 'Recent', icon: 'fa fa-calendar-times', routerLink: ['recent'] },
        { label: 'Search', icon: 'fa fa-search', routerLink: ['search'] }
        //{ label: 'Map', icon: 'fa fa-globe', routerLink: ['map'] }
      ];
    }
   
    else { this.items = []; }
    this.dashboardService.getSalesPersonFilter();
    this.dashboardService.getPieceTypesFilter();
    this.dashboardService.getStatusesFilter();
    switch (this.securityObject.Role) {
      //case "SuperAdmin":
      //case "Admin":
      //case "Account Manager":
      case "Sales":
        this.items = [
          { label: 'Project Manager', icon: 'pi pi-fw pi-table', routerLink: ['projectmanager'] },
          { label: 'Pending Completion', icon: 'fa fa-check-square', routerLink: ['pending'] },
          { label: 'Call Tracking', icon: 'fa fa-phone', routerLink: ['calltracking'] },
        ];
        this.router.navigate(['dashboard/projectmanager']);
        break;
      case "Design":
        this.router.navigate(['dashboard/designer']);
        break;
      case "Print":
      case "Printer":
      case "MagPrinter":
        this.router.navigate(['dashboard/printer']);
        break;
      case "Mailer":
        this.router.navigate(['dashboard/mailer']);
        break;
      case "Shipper":
        this.router.navigate(['dashboard/shipper']);
        break;
       case "DSO":
        this.router.navigate(['dashboard/dso']);  // use child to get GrpName / ctc info
        
        break;
    }
  }

 

}



