import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable({ providedIn: 'root' })
export class AppointmentService {

  constructor(private http: HttpClient) { }

  private headers: HttpHeaders;

  getAppointments(m, y) { return this.http.get('/api/Appointments/' + m + '/' + y); }

  getAppointment(id) { return this.http.get('api/Appointments/' + id); }

  addAppointment(c) { return this.http.post('api/Appointments/', c); }

  deleteAppointment(id) { return this.http.delete('api/Appointments/' + id); }

  updateAppointment(c) { return this.http.put('api/Appointments/' + c.ID, c, { headers: this.headers }); }
}
