import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SecurityService } from '../../security/security.service';
import { AppUserAuth } from '../../security/app-user-auth';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { SelectItem } from 'primeng/api';
import { DashboardService } from '../../Services/DashboardService';
import { CustomersService } from '../../Services/CustomerService';
import * as alertify from 'alertifyjs';
import { isEmpty } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  securityObject: AppUserAuth;
  data;
  cols: any[];
  states;
  sources;
  loading: boolean = false;
  @ViewChild('dt') table: Table;
  Active: boolean = true;
  ServiceCenters;
  selectedServiceCenters: string[] = [];

  constructor(private router: Router, private securityService: SecurityService,
    public dashboardService: DashboardService, public customerService: CustomersService) {
    this.securityObject = securityService.securityObject;
  }

  ngOnInit() {
    if (!this.securityObject.IsAuthenticated) { this.router.navigate(['../../']) };
    this.dashboardService.getStates();
    this.customerService.getSources();

    this.cols = [
      { field: 'custid', header: 'Cust #' },
      { field: 'company', header: 'Company' },
      { field: 'city', header: 'City' },
      { field: 'state', header: 'State' },
      { field: 'contact', header: 'Contact' },
      { field: 'email', header: 'Email' },
      { field: 'source', header: 'Source' },
      { field: 'salesPerson', header: 'Salesperson' },
      { field: 'type', header: 'Type' }
    ];
  }

  ActiveChange(event) {
   
    if (Object.keys(event.filters).length > 0) {
      this.loading = true;
      var state = "";
      var city = "";
      var custid = "";
      var company = "";
      var contact = "";
      var email = "";
      var source = "";
      var salesPerson = "";
      var type = "C";
      //filters: FilterMetadata object having field as key and filter value, filter matchMode as value
      if (event.filters.state) { state = event.filters.state.value; }
      if (event.filters.city) { city = event.filters.City.value; }
      if (event.filters.custid) { custid = event.filters.custid.value; }
      if (event.filters.company) { company = event.filters.company.value; }
      if (event.filters.contact) { contact = event.filters.contact.value; }
      if (event.filters.email) { email = event.filters.email.value; }
      if (event.filters.source) { source = event.filters.source.value; }
      if (event.filters.salesPerson) { salesPerson = event.filters.salesPerson.value; }
      if (event.filters.type) { type = event.filters.type.value; }
      setTimeout(() => {
        this.customerService.getCustomerSearch(state, city, custid, company, contact, email, source, salesPerson, type).subscribe(data => { this.data = data; this.loading = false; }, error => { alertify.error(error.message) });
      }, 1000);
    }
  }
}
