import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Header } from 'primeng/components/common/shared';
import 'rxjs-compat';
import { map } from 'rxjs/operators';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
const requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) };

@Injectable({ providedIn: 'root' })
export class DashboardService {
  public _PieceTypes: any[];
  public _SalespersonFilter;
  public _StatusesFilter;
  public _Statuses;
  public _States;

  constructor(private http: HttpClient) { }

  private headers: HttpHeaders;

  getDashboardDSOReportMonth(u, m, y) { return this.http.get('/Query/GetDSOReportMonth', { params: new HttpParams().set("u", u).set("m", m).set("y", y) }); }

  getDashboardDSOMonth(u, m, y) { return this.http.get('/Query/GetDSOMonth', { params: new HttpParams().set("u", u).set("m", m).set("y", y) }); }

  getDashboardDSOReport(u) { return this.http.get('/Query/GetDSOReport', { params: new HttpParams().set("u", u) }); }

  getDashboard() { return this.http.get('/Query/GetProjectMaster'); }

  getDashboardSales(u) { return this.http.get('Query/GetProjectMasterSales/', { params: new HttpParams().set("u", u) }); }

  getDashboardDesign() { return this.http.get('/Query/GetDesign'); }

  getDashboardPrinter(u) { return this.http.get('/Query/GetPrinter', { params: new HttpParams().set("u", u) }); }

  getDashboardMagPrinter() { return this.http.get('/Query/GetMagPrinter'); }

  getDashboardAllPrinter() { return this.http.get('/Query/GetAllPrinter'); }

  getDashboardMailer() { return this.http.get('/Query/GetMailer'); }

  getDashboardDSO(u) { return this.http.get('/Query/GetDSO', { params: new HttpParams().set("u", u) }); }

  getDashboardDSOInProgress(u) { return this.http.get('/Query/GetDSOInProgress', { params: new HttpParams().set("u", u) }); }

  getDashboardDSOCampaigns(u) { return this.http.get('/Query/GetDSOCampaigns', { params: new HttpParams().set("u", u) }); }

  getDSOCtcs(u) { return this.http.get('/Query/GetDSOContactInfo', { params: new HttpParams().set("u", u) }); }

  getDashboardShipper() { return this.http.get('/Query/GetShipper'); }

  getDashboardPendingCompletion() { return this.http.get('/Query/GetPendingCompletion'); }

  getDashboardPendingCompletionSales(u) { return this.http.get('/Query/GetPendingCompletionSales/', { params: new HttpParams().set("u", u) }); }

  getDashboardCallTracking() { return this.http.get('/Query/GetCallTracking'); }

  getDashboardCallTrackingSales(u) { return this.http.get('/Query/GetCallTrackingSales/,', { params: new HttpParams().set("u", u) }); }

  getDashboardRecent() { return this.http.get('/Query/GetRecent'); }

  getDashboardMap() { return this.http.get('/Query/GetMap'); }

  getPieceTypesFilter() {
    if (this._PieceTypes) return this._PieceTypes;
    else {
      this._PieceTypes = [{ name: '', value: '' }, { name: 'BiFold', value: 'BiFold' }, { name: 'Brochure', value: 'Brochure' }, { name: 'Call Tracking', value: 'Call Tracking' }, { name: 'Insert', value: 'Insert' }, { name: 'Mailing List', value: 'Mailing List' }, { name: 'Magalog', value: 'Magalog' }, { name: 'Magazine', value: 'Magazine' }, { name: 'Paper', value: 'Paper' }, { name: 'Postcard', value: 'Postcard' }, { name: 'Tri-Fold', value: 'Tri-Fold' }];
      return this._PieceTypes;
    }
  };

  getSalesPersonFilter() {
    if (this._SalespersonFilter) { return this._SalespersonFilter; }
    else {
      this.http.get('/Query/GetSalesPeople').subscribe(data => {
        this._SalespersonFilter = data;
        this._SalespersonFilter.unshift({ ID: 0, name: '' });
      });
    }
  }

  getStatusesFilter() {
    if (this._StatusesFilter) { return this._StatusesFilter; }
    else {
      this.http.get('/Maintenance/GetStatus').subscribe(data => {
        this._StatusesFilter = data;
        this._StatusesFilter.unshift({ Status: '', Color: '' });
      });
    }
  }

  getStatuses() {
    if (this._Statuses) { return this._Statuses; }
    else {
      this.http.get('/Maintenance/GetStatus').subscribe(data => {
        this._Statuses = data;
      });
    }
  }

  getStates() {
    if (this._States) { return this._States; }
    else {
      this.http.get('/Query/GetStates').subscribe(data => {
        this._States = data;
        this._States.unshift('');
      });
    }
  }

  updateStatus(id, stat) { return this.http.get('/Query/UpdateStatus', { params: new HttpParams().set("id", id).set("stat", stat) }); };

  updateDesignerCost(id, cost) { return this.http.get('/Query/UpdateDesignerCost', { params: new HttpParams().set("id", id).set("cost", cost) }); };

  updatePrinterCost(id, cost) { return this.http.get('/Query/UpdatePrinterCost', { params: new HttpParams().set("id", id).set("cost", cost) }); };

  updateShipperCost(id, cost) { return this.http.get('/Query/UpdateShipperCost', { params: new HttpParams().set("id", id).set("cost", cost) }); };

  updateNotes(id, notes) { return this.http.get('/Query/UpdateNotes', { params: new HttpParams().set("id", id).set("notes", notes) }); };

  updatePressSheet(id, LayoutNumber) { return this.http.get('/Query/UpdatePressSheet', { params: new HttpParams().set("id", id).set("layoutNumber", LayoutNumber) }); };


  postFile(m) { return this.http.post('/Query/Upload/', m) }

 // gatherCT() { return this.http.get('/Query/GetCallTrackingUpload'); }

  getDSOCallDetails(item) { return this.http.get('/Query/GetDSOCallDetails/' + item); }

  auth = {
    "acces_id": "AVn8H1Zb1Tgcwd5NMO2OunpJICD7yzadKjBZAnnoqych", "secret_key": "zpfq+R4QWWZP/l/k8yTE/cNIK+lJXC8+fZWr5wExh1ql4azs5yr54QgxhRDrYHQiqTnfjcFe2aLCkawQ"
  }


  getVeloxToken() { return this.http.post('https://www.ds-test.tx24sevendev.com/public/auth', this.auth) };

  getIoVox(sid, id, ihd, ed) { return this.http.get('api/Iovox', { params: new HttpParams().set("sid", sid).set("id", id).set("ihd", ihd).set("ed", ed), responseType: 'text' }); }

  getIoVoxCampaign( id, ihd, ed) { return this.http.get('api/Iovox/GetDataCampaign/', { params: new HttpParams().set("id", id).set("ihd", ihd).set("ed", ed)}); }

  getAudio(id, subAcct) {
    return this.http.get('api/Audio', {
      params: new HttpParams().set("id", id).set("subAcct", subAcct)
      , responseType: 'blob'
    });
  }



  //getAudio1(id, subAcct) {
  //  return this.http.get('api/Audio', {
  //    params: new HttpParams().set("id", id).set("subAcct", subAcct)
  //    , headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }).map(res => ({
  //content: res.body,
  //fileName: res.headers.get('content-filename')
  //  }));
  //}


  getTrackingNumberUsage(tn, ihd) { return this.http.get('/Query/GetEndDate/', { params: new HttpParams().set("tn", tn).set("ihd", ihd), responseType: 'text' }) }

  addQCFileUpload(r) { return this.http.post('api/Maintenance/PostQC/', r) }
}


