import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { CustLeadComponent } from './cust-lead/cust-lead.component';
import { StatusBarComponent } from './status-bar/status-bar.component';


@NgModule({
  declarations: [StatusBarComponent],
  imports: [CommonModule],
  exports: [StatusBarComponent]
})
export class SharedModule { }
