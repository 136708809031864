import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable({ providedIn: 'root' })
export class CustomersService {

  constructor(private http: HttpClient) { }

  private headers: HttpHeaders;
  public _Orgs;
  public _ServiceCenters;
  public _Industries;
  public _Sources;
  public _States;
  public _Groups;

  getCustomers(a) { return this.http.get('/Query/GetCustomers', { params: new HttpParams().set("a", a) }); }

  getDSOCustomers(a) { return this.http.get('/Query/GetDSOCustomers', { params: new HttpParams().set("a", a) }); }

  getCUsers() { return this.http.get('Query/GetCUsers');}

  getCCustomer() { return this.http.get('Query/GetCCustomers'); }

  saveUser(newUser) { return this.http.put('/Query/SaveCUser/' + newUser.UserId, newUser, { headers: this.headers }) }

  addCUser(n) { return this.http.post('/Query/AddCUser', n) }

  deleteCUser(u) { return this.http.get('/Query/DeleteCUser/', { params: new HttpParams().set("u", u) }); }

  getCustomer(id) { return this.http.get('api/Customers/' + id); }

  addCustomer(c) { return this.http.post('api/Customers/', c); }

  deleteCustomer(id) { return this.http.delete('api/Customers/' + id); }

  updateCustomer(c) { return this.http.put('api/Customers/' + c.ID, c, { headers: this.headers }); }

  addContact(c) { return this.http.post('api/CustomerContacts/', c); }

  updateContact(c) { return this.http.put('api/CustomerContacts/' + c.ID, c, { headers: this.headers }); }

  deleteContact(c) { return this.http.delete('api/CustomerContacts/' + c); }

  addNote(n) { return this.http.post('api/CustomerNotes/', n); }

  updateNote(n) { return this.http.put('api/CustomerNotes/' + n.ID, n, { headers: this.headers }); }

  deleteNote(n) { return this.http.delete('api/CustomerNotes/' + n); }

  getLeads(a) { return this.http.get('/Query/GetLeads', { params: new HttpParams().set("a", a) }); }

  getLead(id) { return this.http.get('api/Leads/' + id); }

  addLead(c) { return this.http.post('api/Leads/', c); }

  deleteLead(id) { return this.http.delete('api/Leads/' + id); }

  updateLead(c) { return this.http.put('api/Leads/' + c.ID, c, { headers: this.headers }); }

  addLeadContact(c) { return this.http.post('api/LeadContacts/', c); }

  updateLeadContact(c) { return this.http.put('api/LeadContacts/' + c.ID, c, { headers: this.headers }); }

  deleteLeadContact(c) { return this.http.delete('api/LeadContacts/' + c); }

  addLeadNote(n) { return this.http.post('api/LeadNotes/', n); }

  updateLeadNote(n) { return this.http.put('api/LeadNotes/' + n.ID, n, { headers: this.headers }); }

  deleteLeadNote(n) { return this.http.delete('api/LeadNotes/' + n); }

  transferLead(c) { return this.http.get('Query/TransferToCustomer', { params: new HttpParams().set("id", c) }); }

  transferCust(c) { return this.http.get('Query/TransferToLead', { params: new HttpParams().set("id", c) }); }

  getOrgs() {
    if (this._Orgs) return this._Orgs;
    else {
      this.http.get('/Query/GetOrgs').subscribe((data: any) => {
        this._Orgs = data;
        return this._Orgs;
      });
    }
  }

  getServiceCenters() {
    if (this._ServiceCenters) return this._ServiceCenters;
    else {
      this.http.get('/Query/GetServiceCenters').subscribe(data => {
        this._ServiceCenters = data;
        return this._ServiceCenters;
      });
    }
  }

  getIndustries() {
    if (this._Industries) return this._Industries;
    else {
      this.http.get('/Query/GetIndustries').subscribe(data => {
        this._Industries = data;
        this._Industries.unshift({ ID: 0, _Industry: '' });
        return this._Industries;
      });
    }
  }

  getSources() {
    if (this._Sources) return this._Sources;
    else {
      this.http.get('/Query/getSources').subscribe(data => {
        this._Sources = data;
        this._Sources.unshift({ ID: 0, Source: '' });
        return this._Sources;
      });
    }
  }

  getGroups() {
    if (this._Groups) return this._Groups;
    else {
      this.http.get('/Query/GetGroups').subscribe(data => {
        this._Groups = data;
        this._Groups.unshift({ ID: 0, GroupName: '' });
        return this._Groups;
      });
    }
  }

  getNotes() { return this.http.get('/Query/GetNotes'); }

  getReOrders() { return this.http.get('/Query/GetReOrders'); }

  getReOrdersSales(u) { return this.http.get('/Query/GetReOrdersSales/', { params: new HttpParams().set("u", u) }); }

  getCustomerSearch(state, city, custid, company, contact, email, source, salesPerson, type) {
    return this.http.get('Query/GetCustomerSearch',
      {
        params: new HttpParams().set("state", state).set("city", city).set("custid", custid)
          .set("company", company).set("contact", contact).set("email", email)
          .set("source", source).set("salesPerson", salesPerson).set("type", type)
      });
  }

  getCustomerFilter() { return this.http.get('Query/getCustomerFilter/') }

  getCustomerActiveLeadFilter() { return this.http.get('Query/getCustomerActiveLeadFilter/') }

  getSalesidFromUserID(u) { return this.http.get('Query/getSalesIDFromUser', { params: new HttpParams().set("u", u), responseType: 'text' }) }


  //getDashboardCallTrackingUpload() {
  //  return this.http.get('api/Iovox/Reorder'  )
  //};
}
