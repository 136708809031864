import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../Services/DashboardService';
import { AppUserAuth } from '../../security/app-user-auth';
import { Router } from '@angular/router';
import { SecurityService } from '../../security/security.service';
import { OrderService } from '../../Services/OrderService';
import { Item, ItemDisplay } from '../../_interfaces/Order';

@Component({
  selector: 'app-dso-report',
  templateUrl: './dso-report.component.html',
  styleUrls: ['../dashboard.component.css']
})

export class DsoReportComponent implements OnInit {
  securityObject: AppUserAuth;
  data;
  cols: any[];
  groupName: string;
  ctcNames: any = { AcctMgr: '', Salesperson: '', AcctPhone: '', SalesPhone: '' };
  displayDSO: boolean = false;
  headerDisplay: string;
  status: string;
  dataI;
  Year: number = new Date().getFullYear();
  Years: number[] = [this.Year];
  sYear: number = new Date().getFullYear();
  sMonth: number = new Date().getMonth() + 1;
  Month: number = new Date().getMonth();
  Months = [
    {
      "key": 1,
      "value": "January"
    },
    {
      "key": 2,
      "value": "February"
    },
    {
      "key": 3,
      "value": "March"
    },
    {
      "key": 4,
      "value": "April"
    },
    {
      "key": 5,
      "value": "May"
    },
    {
      "key": 6,
      "value": "June"
    },
    {
      "key": 7,
      "value": "July"
    },
    {
      "key": 8,
      "value": "August"
    },
    {
      "key": 9,
      "value": "September"
    },
    {
      "key": 10,
      "value": "October"
    },
    {
      "key": 11,
      "value": "November"
    },
    {
      "key": 12,
      "value": "December"
    }
  ];
  revTotal: number;

  constructor(private router: Router, private securityService: SecurityService,
    private orderService: OrderService, public dashBoard: DashboardService) { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    if (this.securityObject.Role === "DSO") {
      this.sMonth = null;
      this.sYear = null;
      this.dashBoard.getDashboardDSOReport(this.securityObject.Name).subscribe(data => {
        this.data = data; this.totalRev();
      });
      this.dashBoard.getDSOCtcs(this.securityObject.Name).subscribe(data => { this.ctcNames = data; });
    }
    else { this.router.navigate(['../../']) };
    for (let i = this.Year - 1; i > 2020; i--) { this.Years.push(i); }
    this.groupName = this.securityObject.Name;
    // get acct mgr and sp from customer
    this.cols = [
      { field: 'ItemNumber', header: 'Item #' },
      { field: 'InHomeDate', header: 'In Home Date' },
      { field: 'Company', header: 'Location' },
      { field: 'CityState', header: 'City / State' },
      { field: 'Template', header: 'Template' },
      { field: 'Quantity', header: 'Quantity' },
      //{ field: 'Revenue', header: 'Revenue Received' },
      { field: 'Rebate', header: 'Rebate' }
    ];
  }

  getFilterChange() {
    this.dashBoard.getDashboardDSOReportMonth(this.securityObject.Name, this.sMonth, this.sYear).subscribe(data => { this.data = data; this.totalRev()});
  }

  totalRev() {
    this.revTotal = 0;
    for (const y of this.data) {
      this.revTotal += y.Rebate;
    }
  }

  clearDate() {
    this.sMonth = null;
    this.sYear = null;
    this.dashBoard.getDashboardDSOReport(this.securityObject.Name).subscribe(data => { this.data = data; });
  }

  showTemplate(id) {
    this.orderService.GetTemplateImage(id).subscribe(data => {
      let blob = data;
      let url = window.URL.createObjectURL(blob);
      let pwa = window.open(url);
      if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert('Please disable your Pop-up blocker and try again.');
      }
    });
  };
}
