import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from '../../security/security.service';
import { DashboardService } from '../../Services/DashboardService';
import { AccountingService } from '../../Services/AccountingService';
import { AppUserAuth } from '../../security/app-user-auth';
import { IRevQty, customData } from '../../_interfaces/Order';

@Component({
  selector: 'app-custom-sales-rpt',
  templateUrl: './custom-sales-rpt.component.html',
  styleUrls: ['./custom-sales-rpt.component.css']
})
export class CustomSalesRptComponent implements OnInit {
  securityObject: AppUserAuth;
  loading: boolean = false;
  year: number = new Date().getFullYear();
  years: number[] = [this.year];
  oData: IRevQty = new IRevQty;
  gRev: IRevQty = new IRevQty;
  oRev: IRevQty = new IRevQty;

  data;

  constructor(private router: Router, private securityService: SecurityService, public dashboardService: DashboardService,
    private accountingService: AccountingService) { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    this.years[0] = this.year;
    for (let i = this.year - 1; i > 2012; i--) { this.years.push(i); }
    this.getData();
  }

  getData() {
    let x: number = 0;
    this.loading = true;
    this.accountingService.getCustomSalesRpt(this.year).subscribe((data: customData | any) => {
  this.data =   this.transformData(data);
      this.loading = false;
    });
  }

  transformData(data: customData[]): { [key: number]: IRevQty; } {
    const result: { [key: number]: IRevQty; } = {};

    data.forEach((item) => {
      var { SalesPID, OrderMonth, TotalQuantity, TotalRevenue } = item;

      // Ensure an entry exists for the SalesPID
      if (SalesPID !== 98 && SalesPID !== 101) {
        SalesPID = 0;
      }
      if (!result[SalesPID]) {
        result[SalesPID] = new IRevQty();
      }

      // Map OrderMonth to corresponding fields in IRevQty
      const revField = this.getMonthField(OrderMonth, 'rev');
      const qtyField = this.getMonthField(OrderMonth, 'qty');

      if (revField && qtyField) {
        result[SalesPID][revField] += TotalRevenue;
        result[SalesPID][qtyField] += TotalQuantity;
      }

      // Update YTD totals
      result[SalesPID].ytdrev += TotalRevenue;
      result[SalesPID].ytdqty += TotalQuantity;
    });

    return result;
  }

  // Helper function to map month numbers to field names
  getMonthField(month: number, type: 'rev' | 'qty'): string | null {
    const monthMap: { [key: number]: string; } = {
      1: 'jan',
      2: 'feb',
      3: 'mar',
      4: 'apr',
      5: 'may',
      6: 'jun',
      7: 'jul',
      8: 'aug',
      9: 'sep',
      10: 'oct',
      11: 'nov',
      12: 'dec',
    };

    const prefix = monthMap[month];
    return prefix ? `${prefix}${type}` : null;
  }
}

