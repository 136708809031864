import { Component, OnInit } from '@angular/core';
import {  MaintenanceService } from '../../Services/MaintenanceService';
import * as alertify from 'alertifyjs';

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.css']
})
export class StatusBarComponent implements OnInit {
  statuses;
  constructor(private statusService: MaintenanceService) { }

  ngOnInit() {
    this.statusService.getStatus().subscribe(data => { this.statuses = data; }, error => { alertify.error(error.message); });
  }

}
