import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SecurityService } from '../security/security.service';


@Injectable()
export class AuthGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean>
    | Promise<boolean> | boolean {
    // Get property name on security object to check
    let claimType: string = next.data["claimType"];

    if (this.securityService.securityObject.IsAuthenticated
      && this.securityService.hasClaim(claimType)) {
      return true;
    }
    else {
      this.router.navigate([''],
        { queryParams: { returnUrl: state.url } });
      return false;
    }
  }

  constructor(private securityService: SecurityService,
    private router: Router) { }

}
