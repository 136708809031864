import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from '../../Services/DashboardService';
import { OrderService } from '../../Services/OrderService';
import { AppUserAuth } from '../../security/app-user-auth';
import { Router } from '@angular/router';
import { SecurityService } from '../../security/security.service';
 import * as alertify from 'alertifyjs';

@Component({
  selector: 'app-recent',
  templateUrl: './recent.component.html',
  styleUrls: ['../dashboard.component.css']
})
export class RecentComponent implements OnInit {
  securityObject: AppUserAuth;
  data;
  cols: any[];
  
  constructor(private router: Router, private securityService: SecurityService,
    private orderService: OrderService, public dashBoard: DashboardService) { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    if (!this.securityObject.IsAuthenticated || (this.securityObject.Role === "Design" || this.securityObject.Role === "Printer" || this.securityObject.Role === "Mailer" || this.securityObject.Role === "Shipper")) { this.router.navigate(['../../']) };
    this.dashBoard.getDashboardRecent().subscribe(data => { this.data = data; }, error => { alertify.error(error.message) });
    this.cols = [
      { field: 'OrderID', header: 'Order #' },
      { field: 'ItemNumber', header: 'Item #' },
      { field: 'Company', header: 'Company' },
      { field: 'Template', header: 'Template' },
      { field: 'ShipDate', header: 'Ship Date' },
      { field: 'InHomeDate', header: 'In Home Date' },
      { field: 'Quantity', header: 'Quantity' },
      { field: 'Calls', header: '# Calls' },
      { field: 'AcctMgr', header: 'Acct Mgr' },
      { field: 'ID', header: '' },
      { field: 'ItemID', header: '' },
      { field: 'CustID', header: '' },
      { field: 'TemplateID', header: '' },
      { field: 'TemplateFileID', header: '' }
    ];
    this.cols = this.cols.filter(col => col.header !== '')
  }

  showTemplate(id) {
    this.orderService.GetTemplateImage(id).subscribe(data => {
      let blob = data;
      let url = window.URL.createObjectURL(blob);
      let pwa = window.open(url);
      if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert('Please disable your Pop-up blocker and try again.');
      }
    }, (error => { alertify.error('Not Found') })
    )
  };
}
 


