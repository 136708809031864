import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from '../../Services/DashboardService';
import { AppUserAuth } from '../../security/app-user-auth';
import { Router } from '@angular/router';
import { SecurityService } from '../../security/security.service';
import { OrderService } from '../../Services/OrderService';
import * as alertify from 'alertifyjs';

@Component({
  selector: 'app-designer',
  templateUrl: './designer.component.html',
  styleUrls: ['../dashboard.component.css']
})
export class DesignerComponent implements OnInit {
  securityObject: AppUserAuth;
  data;
  cols: any[];
   clonedItems = {};
  loading: true;
  upload;

  constructor(private router: Router, private securityService: SecurityService,
    private orderService: OrderService, public dashBoard: DashboardService) { this.securityObject = securityService.securityObject; }

  ngOnInit() {
    if (!this.securityObject.IsAuthenticated || (this.securityObject.Role === "Printer" || this.securityObject.Role === "Mailer" || this.securityObject.Role === "Shipper")) { this.router.navigate(['../../']) };
    this.dashBoard.getDashboardDesign().subscribe(data => { this.data = data; }, error => { alertify.error(error.message) });
    this.cols = [
      { field: 'Status', header: 'Status' },
      { field: 'Template', header: 'Template' },
      { field: 'OrderID', header: 'Order #' },
      { field: 'ItemNumber', header: 'Item #' },
     // { field: 'PieceType', header: 'Item' },
      { field: 'Company', header: 'Company' },
      { field: 'Address', header: 'Address' },
      { field: 'TargetNumber', header: 'Target #' },
      { field: 'TrackingNumber', header: 'Tracking #' },
     // { field: 'InHomeDate', header: 'In Home Date' },
      { field: 'MailerDate', header: 'Mailer Date' },
      { field: 'ShipDate', header: 'Ship Date' },
      { field: 'Quantity', header: 'Quantity' },
     // { field: 'Size', header: 'Size' },
      { field: 'LayoutNumber', header: 'PressSheet' },
      { field: 'AcctMgr', header: 'Acct Mgr' },
     // { field: 'DesignerCost', header: 'Designer Cost' }
    ];
  }
 
  showTemplate(id) {
    this.orderService.GetTemplateImage(id).subscribe(data => {
      let blob = data;
      let url = window.URL.createObjectURL(blob);
      let pwa = window.open(url);
      if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert('Please disable your Pop-up blocker and try again.');
      }
    }, (error => { alertify.error('Not Found') })
    )
  };

  onEditInit(event): void { this.clonedItems = { ...event.data }; }

  onEditCancel(event): void {
    var y = this.data.findIndex(c => c.SID === event.data.SID);
    this.data[y] = this.clonedItems;
    alertify.warning(event.field + " update cancelled");
  }

  onEditComplete(event): void {
    var y = this.data.findIndex(c => c.SID === event.data.SID);
    if (event.field === 'Status') {
      if (!isNaN(event.data.Status)) {
        this.dashBoard.updateStatus(event.data.SID, event.data.Status)
          .subscribe(data => {
            alertify.success("updated " + event.field);
            if (event.data.Status === "38") this.data[y].Status = 'Priority';
            if (event.data.Status === "35") this.data[y].Status = 'Proof In';
            if (event.data.Status === "34") this.data[y].Status = 'Proof Out';
            if (event.data.Status === "36") this.data[y].Status = 'Ok To Print';
            if (event.data.Status === "39") this.data[y].Status = 'Uploaded To Printer';
            if (event.data.Status === "40") this.data[y].Status = 'Approved For Print';
          },
            error => {
              alertify.error("Error: " + event.field + " not updated");
              this.data[y] = this.clonedItems;
            });
      }
      if (event.field === 'DesignerCost') {
        this.dashBoard.updateDesignerCost(event.data.SID, event.data.DesignerCost)
          .subscribe(data => { alertify.success("updated " + event.field); },
            error => {
              alertify.error("Error: " + event.field + " not updated");
              this.data[y] = this.clonedItems;
            });
      }
    }
    if (event.field === 'LayoutNumber') {
      this.dashBoard.updatePressSheet(event.data.SID, event.data.LayoutNumber)
        .subscribe(data => { alertify.success("updated " + event.field); },
          error => {
            alertify.error("Error: " + event.field + " not updated");
            this.data[y] = this.clonedItems;
          });
    }
  }

  onUploadAuto(event, data) {
    for (let file of event.files) {
      if (file.length === 0) { return; }
      let fileToUpload = file;  
      const formData = new FormData();
      formData.append('PieceType', data.PieceType);
      formData.append('PieceID', data.SID.toString());
      let filename = fileToUpload.name;
      formData.append('file', fileToUpload, filename);
      this.dashBoard.postFile(formData).subscribe(data => {
        var y = this.data.findIndex(c => c.SID === event.data.SID);
        this.data[y].TemplateFileID = data;
        alertify.success("Upload Successful");
      }, error => {
        alertify.error("Error: File not uploaded.");
      });
    }
  }

}

